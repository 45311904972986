import { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { Button, Card, Col, Form, Row, Stack, Table } from 'react-bootstrap';
import { useAuth } from '../../firebase/auth';
import TablePlaceholder from '../../components/TablePlaceholder';
import { format } from 'date-fns';

import { query, collection, where, getDocs, Timestamp, startAfter, orderBy, limit } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';

import { functions } from "../../firebase/firebase"
import { httpsCallable } from 'firebase/functions'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const pageSize = 5;

export default function TransactionsCreateCustomerSelect() {
    const { authUser } = useAuth();

    const navigate = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    
    // search
    const [constraints, setConstraints] = useState();
    const [searchQuery, setSearchQuery] = useState();
    const [lastQuery, setLastQuery] = useState();

    // pagination
    const [page, setPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [pageData, setPageData] = useState([]);


    useEffect(() => {
        setSelectedCustomer(null);
        setCustomers(null);
        setPageData([]);
        setLastPage(null);
        setLastDoc(null);

        if(authUser.role === "clerk"){
            searchCustomersClerk().then((updatedCustomers) => {
                setPageData([updatedCustomers]);
                setCustomers(updatedCustomers);
            });
        }else{
            fetchCustomers().then((updatedCustomers) => {
                setPageData([updatedCustomers]);
                setCustomers(updatedCustomers);
            });
        }

        setPage(1);
    }, [constraints]);

    useEffect(() => {
        if(page > pageData.length && page){
            fetchCustomers(lastDoc)
            .then((updatedCustomers) => {
                setPageData([...pageData, updatedCustomers]);
                setCustomers(updatedCustomers);
            });
        }else{
            setCustomers(pageData[page-1]);
        }
    }, [page]);

    const handleNext = async () => {
        setPage(page+1);
    }

    const handlePrev = async () => {
        setPage(page-1);
    }

    const fetchCustomers = async (afterDoc=null) => {
        const customersRef = collection(db, 'companies', authUser.company.uid, 'customers');
        const queryConstraints = [orderBy('createdAt', 'desc'), limit(pageSize+1), where("status.status", "==", "approved")];
        if (constraints) {
            queryConstraints.push(constraints);
        }
        if (afterDoc) {
            queryConstraints.push(startAfter(afterDoc));
        }
        const q = query(customersRef, ...queryConstraints);
        const snapshot = await getDocs(q);
        if (snapshot.docs.length <= pageSize) {
            setLastPage(page);
        }
        setLastDoc(snapshot.docs[snapshot.docs.length === pageSize + 1 ? snapshot.docs.length - 2 : snapshot.docs.length - 1]);
        
        let updatedCustomers = [];
        snapshot.forEach((doc) => {
            updatedCustomers.push({
                uid: doc.id,
                ...doc.data(),
            });
        });
        updatedCustomers = updatedCustomers.slice(0, pageSize);
        return updatedCustomers;
    }

    const searchCustomersClerk = async () => {
        const searchCustomers = httpsCallable(functions, "searchCustomers");
        const data = {searchQuery: searchQuery};
        return await searchCustomers(data);
    }

    const handleSelectCustomer = (customer) => {
        if(selectedCustomer === customer){
            setSelectedCustomer(null);
            return;
        }else{
            setSelectedCustomer(customer);
        }
    };

    const handleSearchQuery = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        if (lastQuery && searchQuery === ""){
            setConstraints();
            setLastQuery();
            return;
        }
        if (searchQuery === lastQuery) {
            return;
        }
        if (searchQuery && searchQuery.trim().length > 0) {
            setConstraints(where("keywords", "array-contains-any", searchQuery.split(" ")))
            setLastQuery(searchQuery);
        }
    }

    const handleSubmit = () => {
        navigate("./"+selectedCustomer.uid,
            {relative: "path", state: {customer: selectedCustomer}}
        );
    }

    return (
        <Page title="Create Transaction" compact={true}>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Header>
                            Select Customer
                            <Form onSubmit={handleSearch} className="mt-2">
                                <Stack direction="horizontal" gap={2}>
                                    <Form.Control onChange={handleSearchQuery} className="form-control" type="text" name="name" placeholder="Search for a customer" />
                                    <Button type="submit" variant="primary" className='d-inline-block'>Search</Button>
                                </Stack>
                            </Form>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {customers && customers.length === 0 && <p className="p-4">No customers found. Please try another search.</p>}
                            <Table responsive hover className="small">
                                {customers && customers.length > 0 &&
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Customer</th>
                                        <th>Created At</th>
                                        <th>Created By</th>
                                    </tr>
                                </thead>
                                }
                                <tbody>
                                    {!customers &&
                                        <TablePlaceholder columnCount={4}/>
                                    }
                                    {customers && customers.map((customer) => (
                                        <tr key={customer.uid} onClick={() => handleSelectCustomer(customer)} className={selectedCustomer && selectedCustomer === customer ? "bg-primary-soft" : ""}>
                                            <td>
                                                <Form.Check 
                                                    type="radio" 
                                                    name="selectedCustomer" 
                                                    checked={selectedCustomer && selectedCustomer === customer}
                                                />
                                            </td>
                                            <td>
                                                <p className="mb-0 fw-bold">{customer.firstName} {customer.lastName}</p>
                                                <p className="mb-0 text-muted">ID {customer.uid}</p>
                                            </td>
                                            <td>{format(new Timestamp(customer.createdAt.seconds, customer.createdAt.nanoseconds).toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                                            <td>{customer.createdByName}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            { selectedCustomer && 
                            <Button variant="primary" onClick={handleSubmit}>
                                Create Transaction
                            </Button>
                            }
                            { page!==1 &&
                            <Button variant="primary-soft text-primary" size="sm" onClick={handlePrev} className="ms-2 me-2">
                                <FontAwesomeIcon icon={faChevronLeft}/>
                            </Button>
                            }
                            { customers && page!==lastPage &&
                            <Button variant="primary-soft text-primary" size="sm" onClick={handleNext}>
                                <FontAwesomeIcon icon={faChevronRight}/>
                            </Button>
                            }
                        </Card.Footer>
                    </Card>                
                </Col>
            </Row>
        </Page>
    );
};
