import Page from '../../layout/Page';
import { Row } from 'react-bootstrap'; // Assuming you have Card and Table components from react-bootstrap
import LevelCreateKYCForm from '../../forms/levels/LevelCreateKYCForm';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function LevelsCreate() {

    const location = useLocation();
    const [levelType, setLevelType] = useState("kyc");

    useEffect(() => {
        if(location.state && location.state.levelType){
            setLevelType(location.state.levelType);
        }
    }, [levelType]);
    
    return (
        <Page.AdminOrOfficer title={"Create "+levelType.toUpperCase()+" Level"} description="Levels">
            <Row>
                {levelType === "kyc" &&
                    <LevelCreateKYCForm levelType={levelType}/>
                }
            </Row>
        </Page.AdminOrOfficer>
    );
}
