import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { sendVerificationEmail, verifyEmailCode } from "../../firebase/otp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export default function LinkKYCEmailVerificationForm({email, onComplete}) {
    const [code, setCode] = useState("");
    const [user, setUser] = useState();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [verifyButton, setVerifyButton] = useState();
    const [emailVerified, setEmailVerified] = useState(false);
    const [statusMessage, setStatusMessage] = useState();

    let emailSent = false;

    useEffect(() => {
        if(!emailSent) sendEmail();
    }, []);

    const sendEmail = async () => {
        emailSent = true;
        const result = await sendVerificationEmail(email);
        if (!result) {
            // todo implement exception
            return
        }
        if (result.success) {
            setIsEmailSent(true);
        }
    }
    
    const handleVerify = async () => {
        verifyButton.disabled = true;
        const result = await verifyEmailCode(email, code)
        if (!result) {
            // todo implement exception
            return;
        }
        if(!result.success) {
            setStatusMessage(result.message);
            verifyButton.disabled = false;
            return;
        }
        if (result.success) {
            setEmailVerified(true);
        }
    }
    
    return (
        <>
            { emailVerified ?
            <Col>
                <FontAwesomeIcon icon={faCircleCheck} className="text-primary display-6 mb-2" />
                <h1 className="card-title">E-mail Verification Completed</h1>
                <p className="text-body">Your email address is verified successfully.</p>
                <Button variant="primary" type="submit" className="d-inline-block w-25 float-end mt-3" onClick={onComplete}>Next</Button>
            </Col>
            :
            <Col>
                <h1 className="card-title">E-mail Verification</h1>
                {
                !isEmailSent ?
                <Spinner variant="primary" animation="border" />
                : 
                <>
                <p className="text-body">We sent an OTP code to email address: {email}</p>
                <Form.Group className="mb-3">
                    <Form.Control 
                        className="form-control-solid"
                        type="text" 
                        placeholder="Enter your OTP code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)} 
                    />
                    { statusMessage && <Form.Text className="text-danger">{statusMessage}</Form.Text> }
                </Form.Group>
                <Button onClick={handleVerify} ref={setVerifyButton}>Verify</Button>
                </>
                }   
            </Col>
            }
        </>
    );
}
