import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import TablePlaceholder from "./TablePlaceholder";
import { format, set } from "date-fns";
import { useState } from "react";
import { useAuth } from "../firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

export default function CustomerNotes({customer}){

    const { authUser } = useAuth();
    
    let notes = [];
    const [showModal, setShowModal] = useState(false);
    
    if(customer && customer.notes){
        notes = customer.notes;
        notes = notes.sort((a, b) => b.date - a.date);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const note = form.note.value;
        const newNote = {
            note: note,
            date: set(new Date(), {seconds: 0}),
            createdBy: authUser.uid,
            createdByName: authUser.displayName
        };
        const customerRef = doc(db, "companies", authUser.company.uid ,"customers", customer.id);
        const newNotes = [...notes, newNote];
        setDoc(customerRef, {notes: newNotes}, {merge: true})
        .then(()=>{
            setShowModal(false);
        })
        .catch((error)=>{
            console.error("Error adding document: ", error);
        });
    }

    const columns = ["Date", "Note", "Created By"];
    const rowRenderer = (note) => {
        return (
            <tr key={note.uid}>
                <td>{format(note.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td className="w-75 text-wrap text-break">{note.note}</td>
                <td>{note.createdByName}</td>
            </tr>
        );
    };

    return(
        <>
        <div className="text-end mb-2">
            <Button size="sm" className="" onClick={()=>setShowModal(true)} variant="primary">Create New</Button>
        </div>
        <Card>
            <Card.Body className="p-0">
                <Table responsive hover className="small">
                    <thead>
                        <tr>
                        {
                            columns.map((column) => (
                                <th key={column}>{column}</th>
                            ))
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {customer && notes && notes.map(rowRenderer)}
                        {!customer && <TablePlaceholder columnCount={columns.length}/>}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        <Modal key="noteCreate" show={showModal} onHide={()=>setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Control name="note" as="textarea" rows={3}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
        </>
    );

}