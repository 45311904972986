import Page from '../../layout/Page';
import {useAuth } from '../../firebase/auth';
import { Card, Col, Row } from 'react-bootstrap';
import { faBuildingCircleCheck, faFileCirclePlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function HomeRestricted() {

    const { authUser } = useAuth();

    function NavCard({subtitle, title, icon, color, link}){
        return (
            <Col xs={12} md={4} className="mb-3">
                <a className={`card bg-${color} lift lift-md text-white h-100 py-3 text-decoration-none`}>
                    <Link to={link} className="text-white text-decoration-none stretched-link">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="me-3">
                                <div className="text-white-75 small">{subtitle}</div>
                                <div className="text-lg fw-bold">{title}</div>
                            </div>
                            <FontAwesomeIcon icon={icon} className="text-white-50 fs-1" />
                        </div>
                    </div>
                    </Link>
                </a>
            </Col>
        );
    }

    return (
       <Page title="Dashboard" description="Overview">
            <Row>
                <NavCard link="./customers/create" subtitle="KYC" title="Verify Customer" icon={faUserPlus} color="primary" />
                <NavCard link="./businesses/create" subtitle="KYB" title="Verify Business" icon={faBuildingCircleCheck} color="success" />
                <NavCard link="./transactions/create" subtitle="KYT" title="Create Transaction" icon={faFileCirclePlus} color="secondary" />
            </Row>
       </Page>
    );
};