import { useState } from "react";
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Row, Stack } from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase/auth";

export default function LevelCreateKYCForm({levelType}){

    const { authUser } = useAuth();
    const navigate = useNavigate();

    const defaultFields = [
        "First Name",
        "Last Name",
        "Date of Birth",
        "Nationality",
        "Phone",
        "Email",
    ]

    const documents = [
        "ID Card",
        "Passport",
        "Driving License",
        "Residence Permit",
        "Proof of Address",
        "Proof of Payment",
        "Bank Statement",
        "Student ID",
        "Work Permit",
        "Tax Document"
    ]

    const types = [
        "Text",
        "Number",
        "Date",
    ]
    
    const verifications = [
        "E-mail Verification",
        "SMS Verification",
    ]

    const [levelName, setLevelName] = useState(undefined);
    const [fields, setFields] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const [selectedVerifications, setSelectedVerifications] = useState([])

    const handleLevelNameChange = (e) => {
        setLevelName(e.target.value);
    }

    const handleDocumentChange = (e) => {
        const selected = [...selectedDocuments];
        if (e.target.checked) {
            selected.push(e.target.name);
        } else {
            const index = selected.indexOf(e.target.name);
            if (index > -1) {
                selected.splice(index, 1);
            }
        }
        setSelectedDocuments(selected);
    }
    
    const handleVerificationChange = (e) => {
        const selected = [...selectedVerifications];
        if (e.target.checked) {
            selected.push(e.target.name);
        } else {
            const index = selected.indexOf(e.target.name);
            if (index > -1) {
                selected.splice(index, 1);
            }
        }
        setSelectedVerifications(selected);
    }

    const handleAddField = () => {
        const newFields = [...fields, { name: "", type: "" }];
        setFields(newFields);
    };

    const handleDeleteField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    const handleFieldNameChange = (index, e) => {
        const newFields = [...fields];
        newFields[index].name = e.target.value;
        setFields(newFields);
    };

    const handleFieldTypeChange = (index, e) => {
        const newFields = [...fields];
        newFields[index].type = e.target.value;
        setFields(newFields);
    };

    const validateForm = (level) => {
        if (level.name === undefined) {
            alert("Please enter a level name");
            return false;
        }

        if (level.fields.some(field => field.name === "")) {
            alert("Please enter a name for each field");
            return false;
        }

        const fieldNames = level.fields.map(field => field.name);
        const uniqueFieldNames = [...new Set(fieldNames)];
        if (fieldNames.length !== uniqueFieldNames.length) {
            alert("Please enter unique field names");
            return false;
        }

        if (level.documents.length === 0) {
            alert("Please select at least one document");
            return false;
        }

        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const newLevelObj = {
            name: levelName,
            fields: fields,
            documents: selectedDocuments,
            verifications: selectedVerifications,
            isDeleted: false,
            type: levelType,
        }

        if (!validateForm(newLevelObj)) {
            return;
        }

        const newLevelRef = collection(db, "companies", authUser.company.uid, "levels");
        addDoc(newLevelRef, newLevelObj)
            .then(() => {
                navigate("..", { relative: "path" })
            })
            .catch((error) => {
                alert("Error adding document: ", error);
            });
    }

    return(
        <Form>
            <Card className="mb-3">
                <Card.Header>
                    Level Name
                </Card.Header>
                <Card.Body>
                    <Form.Control className="form-control-solid" type="text" placeholder="Enter level name" onChange={handleLevelNameChange}/>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>
                    Personal Information
                </Card.Header>
                <Card.Body>
                    <ListGroup variant="flush">
                        {defaultFields.map((field) => (
                            <ListGroup.Item className="ps-0 text-body">
                                {field}
                            </ListGroup.Item>
                        ))}
                    {fields.map((field, index) => (
                        <ListGroupItem className="ps-0 text-body">
                            <Stack key={index} direction="horizontal" gap={3}>
                                <Row className="w-100">
                                    <Col>
                                        <Form.Control
                                            className="form-control-solid"
                                            type="text"
                                            placeholder="Field name"
                                            value={field.name}
                                            onChange={(e) => handleFieldNameChange(index, e)}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            className="form-control-solid"
                                            as="select"
                                            value={field.type}
                                            onChange={(e) => handleFieldTypeChange(index, e)}
                                        >
                                            <option>Field type</option>
                                            {types.map((type, typeIndex) => (
                                                <option key={typeIndex}>{type}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Button
                                    variant="danger-soft text-danger"
                                    className="align-self-end"
                                    onClick={() => handleDeleteField(index)}
                                >
                                    Delete
                                </Button>
                            </Stack>
                        </ListGroupItem>
                    ))}
                    <ListGroupItem className="ps-0 pt-0 text-body">
                        <Button variant="outline-primary mt-2" onClick={handleAddField}>
                            Add Field
                        </Button>
                    </ListGroupItem>
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>
                    Required Documents
                </Card.Header>
                <Card.Body>
                    <Row>
                        {documents.map((document) => (
                            <Form.Group key={document} controlId={document} className="col-12 col-md-6 col-lg-4 mb-1">
                                <Form.Check type="checkbox" name={document} label={document} onChange={handleDocumentChange} />
                            </Form.Group>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>
                    Verification
                </Card.Header>
                <Card.Body>
                    <Row>
                        {verifications.map((verification) => (
                            <Form.Group key={verification} controlId={verification} className="col-12 col-md-6 col-lg-4 mb-1">
                                <Form.Check type="checkbox" name={verification} label={verification} onChange={handleVerificationChange} />
                            </Form.Group>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
            <Button variant="primary" className="ms-auto" onClick={handleSubmit}>Save</Button>
        </Form>
    );
}