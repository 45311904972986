import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function NoResultCard({title, description, link=null, onClick=null}) {
    return(
        <div className="card-body text-center p-5">
            <FontAwesomeIcon icon={faCircleQuestion} className="display-3 text-gray-500 mb-3"/>
            <h4>{title}</h4>
            <p className="mb-4">{description}</p>
            {link ?
                <Link to={link}>
                    <a className="btn btn-primary p-3">Create New</a>
                </Link>
            :
            onClick ?
                <a className="btn btn-primary p-3" onClick={onClick}>Create New</a>
            : ""
            }
        </div>
    );
}