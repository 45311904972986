import { useState } from "react";
import { Button, Form } from "react-bootstrap";

let files = {};

export default function LinkKYCDocumentForm({level, handleSubmit}){

    const documents = level.documents;

    const [documentStep, setDocumentStep] = useState(0);
    const [inputRef, setInputRef] = useState();

    const handleChange = (event) => {
        files[event.target.name] = event.target.files[0];
        console.log(files);
    }

    const handleNext = (event) => {
        event.preventDefault();
        setDocumentStep(documentStep + 1);
        inputRef.value = "";
    }
    
    const checkSubmit = (event) => {
        event.preventDefault();
        if (documentStep === documents.length - 1) {
            handleSubmit(event, files);
        }else{
            handleNext(event);
        }
    }

    return(
        <Form onSubmit={checkSubmit}>
            <Form.Group className="mb-3">
                <h1 className="card-title mb-4">Upload Documents</h1>
                <Form.Label>{documents.at(documentStep)}</Form.Label>
                <Form.Control ref={setInputRef} className="form-control-solid" required name={documents.at(documentStep)} type="file" onChange={handleChange} />
            </Form.Group>
            <Button variant="primary" type="submit" className="d-inline-block w-25 float-end mt-3">
                {documentStep === documents.length - 1 ? "Submit" : "Next"}
            </Button>
        </Form>
    );
}
