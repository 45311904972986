import Page from '../layout/Page';
import { NavLink, Route, Routes } from 'react-router-dom';
import Account from './Account';
import { Nav } from 'react-bootstrap';

export default function Settings() {

    return (
        <Page title="Settings" description="Settings">
            <Nav className="nav nav-borders">
                <NavLink className="nav-link" to="/dashboard/settings/account">Account</NavLink>
            </Nav>
            <hr className="mt-0 mb-4" />
            
            <Routes>
                <Route path="account" element={<Account/>} />
            </Routes>
            
        </Page>
    );
};