import React from 'react';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import QRCode from 'react-qr-code';

function QRModal({ show, link, onClose }) {
    const handleCopyLink = () => {
        try {
            navigator.clipboard.writeText(link);
            // toast/notification here to indicate success
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center pb-3">
                    <QRCode level={"L"} value={link} size={200} />
                </div>
                <InputGroup>
                    <Form.Control type="text" value={link} readOnly />
                    <Button onClick={handleCopyLink}>
                        Copy
                    </Button>
                </InputGroup>
                <Button variant="primary-soft text-primary w-100 mt-2" onClick={onClose}>
                    Close
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default QRModal;
