import { deleteObject, getDownloadURL as getStorageDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './firebase';
 
// Bucket URL from Storage in Firebase Console
const BUCKET_URL = "gs://"+process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
 
// Uploads file and returns the storage bucket
export async function uploadFile(image, bucketPath, fileName) {
  let fileExtension = '.jpg'; // Default extension

  switch (image.type) {
    case 'image/jpeg':
      fileExtension = '.jpg';
      break;
    case 'image/png':
      fileExtension = '.png';
      break;
    case 'application/pdf':
      fileExtension = '.pdf';
      break;
    // Add more cases as needed for other file types
    default:
      // Attempt to derive extension from MIME type directly
      const mimeExtension = image.type.split('/')[1];
      if (mimeExtension) {
        fileExtension = `.${mimeExtension}`;
      }
      break;
  }
    
  const bucket = `${BUCKET_URL}/${bucketPath}/${fileName}${fileExtension}`; // Use fileName with extension
  try {
    await uploadBytes(ref(storage, bucket), image);
    return bucket;
  } catch (e) {
    return null;
  }
}


// Replaces existing file in storage and returns the storage bucket
export function replaceFile(image, bucket) {
  uploadBytes(ref(storage, bucket), image);
}

// Deletes existing file in storage
export function deleteFile(bucket) {
  deleteObject(ref(storage, bucket));
}

// Gets the download URL from the reference URL
export async function getDownloadURL(bucket) {
  return await getStorageDownloadURL(ref(storage, bucket));
}
