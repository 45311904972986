import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Header({title, description=null, compact=false, headerContent=null}){

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("..", { relative: "path" })
    }

    return(
        !compact ?
        (
            <header className="page-header page-header-light mb-3">
                <div className="container">
                    <div className="page-header-content py-3 mt-3 border-bottom">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto">
                                {description && 
                                    <span className="text-muted text-sm">{description}</span>
                                }
                                <h1 className="text-dark mb-0 fw-600">{title}</h1>
                            </div>
                            { headerContent }
                        </div>
                    </div>
                </div>
            </header>
        )
        :
        (
            <header className="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
                <div className="container-xl">
                    <div className="page-header-content">
                        <div className="row align-items-center justify-content-between pt-3">
                            <div className="col-auto mb-3">
                            <h1 className="page-header-title">
                                <Button className="btn btn-light me-3" onClick={handleBack} style={{ height: 10+"px", width: 10+"px" }}>
                                    <FontAwesomeIcon icon={faChevronLeft}/>
                                </Button>
                                {title}
                            </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )

    );
}