export default function RiskTag({risk}){

    const colors = {
        low: "success",
        medium: "warning",
        high: "danger"
    }

    const riskText = {
        low: "Low",
        medium: "Medium",
        high: "High"
    }

    return(
        <span className={`badge bg-${colors[risk]}-soft text-${colors[risk]}`}>{riskText[risk]}</span>
    );
}