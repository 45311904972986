import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './firebase/auth';

import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './utils/PrivateRoute';
import PublicOnlyRoute from './utils/PublicOnlyRoute';
import LinkKYC from './pages/link/LinkKYC';
import MemberRegistration from './pages/members/MemberRegistration';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='dashboard/*' element={<Dashboard />} />
            <Route path='logout' element={<Logout />} />
          </Route>
          <Route element={<PublicOnlyRoute />}>
            <Route path='/invitations/:invitationId' element={<MemberRegistration />}/>
            <Route path='/link/:linkId' element={<LinkKYC />}/>
            <Route path='register' element={<Register />} />
            <Route path='login' element={<Login />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
