import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import PhoneInput from '../../components/PhoneInput';
import CountryInput from '../../components/CountryInput';

export default function LinkKYCPersonalInformationForm({handlePersonalInformation, isLoading}){

    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handlePersonalInformation(formData);
    }

    return(
        <Form onSubmit={handleSubmit}>
            <h1 className="card-title mb-4">Enter your personal information</h1>
            <Form.Group className='mb-2'>
                <Form.Label>First Name</Form.Label>
                <Form.Control required className="form-control-solid" type="text" name="firstName" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className='mb-2'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control required className="form-control-solid" type="text" name="lastName" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className='mb-2'>
                <CountryInput onChange={handleChange}/>
            </Form.Group>
            <Form.Group className='mb-2'>
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control required className="form-control-solid" type="date" name="dateofbirth" onChange={handleChange}/>
            </Form.Group>
            <Form.Group className='mb-2'>
                <PhoneInput onChange={handleChange}/>
            </Form.Group>
            <Form.Group className='mb-2'>
                <Form.Label>Email</Form.Label>
                <Form.Control required className="form-control-solid" type="text" name="email" onChange={handleChange}/>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading} className="d-inline-block w-25 float-end mt-3">
                {isLoading && <Spinner variant="light" animation="border" size="sm" className="me-2" />}
                Next
            </Button>
        </Form>
    );
}
