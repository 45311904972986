import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { useAuth } from '../../firebase/auth';

export default function LoginForm() {

    const { login } = useAuth();
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({});

    const handleLogin = async (e) => {
        e.preventDefault();
        const { email, password } = formData;
        try {
            await login(email, password);
        } catch (error) {
            if(error.message == "Firebase: Error (auth/invalid-login-credentials).") {
                setError("Invalid login credentials.");
            }else{
                setError(error.message);
            }
        }
    };

    const handleChange = (event) => {
        setError(null);
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    return (
        <Form>
            { error && <Alert variant="danger">{error}</Alert> }
            <div className="mb-3">
                <Form.Label className="small mb-1">Email</Form.Label>
                <Form.Control className="form-control-solid" type="text" name="email" onChange={handleChange} />
            </div>
            <div className="mb-3">
                <Form.Label className="small mb-1">Password</Form.Label>
                <Form.Control className="form-control-solid" type="password" name="password" onChange={handleChange} />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                <a className="small d-none" href="auth-password-basic.html">Forgot Password?</a>
                <Button type="submit" className="w-100" onClick={handleLogin}>Login</Button>
            </div>
        </Form>
    );
}