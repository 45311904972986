import { Link } from 'react-router-dom';
import LayoutAuth from '../../layout/LayoutAuth';
import RegisterForm from '../../forms/auth/RegisterForm';
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function Register() {

    return (
        <LayoutAuth>
            <Col xs={12}>
                <h1 className="mb-0">Create Account</h1>
                <div className="small mb-3">
                    <Link to="/login">Already have an account? Log In.</Link>
                </div>
                <RegisterForm/>
            </Col>
        </LayoutAuth>
    );
}
