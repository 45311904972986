import { faBuilding, faClipboardUser, faFileCirclePlus, faFingerprint, faGear, faLayerGroup, faList, faMoneyCheck, faRightFromBracket, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import SidenavLink from './SidenavLink';
import SidenavHeading from './SidenavHeading';
import AuthControl from '../components/AuthControl';
import { useAuth } from '../firebase/auth';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Sidenav() {

    const { logout, authUser } = useAuth();
    const name = authUser.displayName;
    const role = authUser.role === 'admin' ? 'Admin' : authUser.role === 'officer' ? 'Officer' : 'Clerk';
    
    return (
        <nav className="sidenav sidenav-dark">
            <Link className="navbar-brand d-flex align-items-center gap-2 ps-3 pt-3" to="/dashboard">
                <FontAwesomeIcon icon={faFingerprint} className="bg-gradient-primary-to-secondary fs-1 p-1 rounded text-white"/>
                <span className="text-white fs-5">True<span className="fw-bold">KYC</span><span className="fw-light text-white-50 ms-1 text-xs">{role}</span></span>
            </Link>
            <div className="sidenav-menu">
                <div className="nav accordion" id="accordionSidenav">
                    <SidenavHeading title="Overview" />
                    <SidenavLink icon={faTachometerAlt} text="Dashboard" link="/dashboard" isEnd={true}/>
                    <SidenavHeading title="Verifications" />
                    <AuthControl.AdminOrOfficer>
                        <SidenavLink icon={faUser} text="Customer Verifications" link="/dashboard/customers"/>
                        <SidenavLink icon={faBuilding} text="Business Verifications" link="/dashboard/businesses"/>
                    </AuthControl.AdminOrOfficer>
                    <AuthControl.Clerk>
                        <SidenavLink icon={faUser} text="Customer Verification" link="/dashboard/customers/create"/>
                        <SidenavLink icon={faBuilding} text="Business Verifications" link="/dashboard/businesses/create"/>
                    </AuthControl.Clerk>
                    <SidenavHeading title="Monitoring" />
                    <AuthControl.AdminOrOfficer>
                        <SidenavLink icon={faList} text="Transaction Monitoring" link="/dashboard/transactions"/>
                    </AuthControl.AdminOrOfficer>
                    <AuthControl.Clerk>
                        <SidenavLink icon={faFileCirclePlus} text="New Transaction" link="/dashboard/transactions/create"/>
                    </AuthControl.Clerk>
                    <SidenavHeading title="Settings" />
                    <AuthControl.AdminOrOfficer>
                        <SidenavLink icon={faLayerGroup} text="Levels" link="/dashboard/levels"/>
                    </AuthControl.AdminOrOfficer>
                    <AuthControl.Admin>
                        <SidenavLink icon={faClipboardUser} text="Team" link="/dashboard/members"/>
                        <SidenavLink icon={faMoneyCheck} text="Billing" link="/dashboard/billing"/>
                    </AuthControl.Admin>
                    <SidenavLink icon={faGear} text="Settings" link="/dashboard/settings/account"/>
                </div>
            </div>
            <div className="sidenav-footer">
                <div className="sidenav-footer-content flex-grow-1">
                    <div className="sidenav-footer-subtitle lh-1 text-primary">{role}</div>
                    <div className="sidenav-footer-title">{name}</div>
                </div>
                <Link onClick={logout} className="btn btn-outline-primary btn-xs p-2">
                    <FontAwesomeIcon icon={faRightFromBracket} className="me-1"/>
                    Logout
                </Link>
            </div>
        </nav>
    );
}
