import { Button, Nav, Row, Stack } from "react-bootstrap";
import RiskDropdown from "../components/RiskDropdown";
import StatusTag from "../components/StatusTag";

export default function CustomerHeader({customer, risk, onSelectRisk, check, onCheck, onCancel, onSave}){
    return(
        <div className="page-header-content mb-4 border-bottom">
            <Row className="align-items-center justify-content-between">
                <div className="col-12 col-md-6">
                    <span className="text-muted text-sm">ID: {customer.id}</span>
                    <h1 className="d-flex align-items-center text-dark mb-0 fw-600">
                        {customer.firstName} {customer.lastName}
                        <span className="ms-2 fs-6">
                            <StatusTag status={customer.status.status} />
                        </span>
                    </h1>
                    { customer.status.tags && customer.status.tags.length >0 &&
                        <div className="mb-2">
                            <div>
                                {customer.status.tags.map((tag) => (
                                    <span key={tag} className="badge bg-danger-soft text-danger me-1">{tag}</span>
                                ))}
                            </div>
                        </div>
                    }
                </div>
                <Stack className="col-12 col-md-auto justify-content-between" direction="horizontal" gap={2}>
                    <RiskDropdown risk={risk} onSelect={onSelectRisk}/>
                    { check &&
                        <>
                            <Button size="sm" className="btn-primary-soft text-primary" onClick={onCancel}>Cancel</Button>
                            <Button size="sm" className="btn-primary" onClick={onSave}>Finish Check</Button>
                        </>
                    }
                    { !check &&
                        <Button size="sm" className="btn-primary" onClick={onCheck}>Start Check</Button>
                    }
                </Stack>
            </Row>
            <Nav className="nav nav-borders mt-2">
                <Nav.Item>
                    <Nav.Link className="text-sm mx-0 me-3" eventKey="verification">Documents</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="text-sm mx-0 me-3" eventKey="transactions">Transactions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className="text-sm mx-0 me-3" eventKey="notes">Notes</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};