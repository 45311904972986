import { useAuth } from "../firebase/auth";

// Define Admin component
function Admin({ children }) {
  const { authUser } = useAuth();
  if (authUser && authUser.role === "admin") {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

// Define AdminOrOfficer component
function AdminOrOfficer({ children }) {
  const { authUser } = useAuth();
  if (authUser && (authUser.role === "admin" || authUser.role === "officer")) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

function Clerk({ children }) {
  const { authUser } = useAuth();
  if (authUser && (authUser.role === "clerk")) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

// Define AuthControl component
function AuthControl({ children }) {
  return <>{children}</>;
}

// Attach Admin and AdminOrOfficer as properties to AuthControl
AuthControl.Admin = Admin;
AuthControl.AdminOrOfficer = AdminOrOfficer;
AuthControl.Clerk = Clerk;

export default AuthControl;
