import Page from '../../layout/Page';
import { Card, Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CustomerCreateForm from '../../forms/customers/CustomerCreateForm';
import LevelSelectForm from '../../forms/levels/LevelSelectForm';
import { useEffect, useState } from 'react';


export default function CustomersCreate() {

    const location = useLocation();
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [levels, setLevels] = useState(null);

    useEffect(() => {
        if(location.state && location.state.level){
            setSelectedLevel(location.state.level);
        }
    }, [location]);

    return (
        <Page title="Create Customer" compact={true}>
            <Row>
                { selectedLevel ? 
                <Col>
                    <CustomerCreateForm level={selectedLevel}/>
                </Col>
                :
                <Col>
                    <Card>
                        <Card.Header>
                            Level
                        </Card.Header>
                        <Card.Body>
                            <LevelSelectForm levels={levels} setLevels={setLevels} setSelectedLevel={setSelectedLevel}/>
                        </Card.Body>
                    </Card>
                </Col>
                }
            </Row>
        </Page>
    );
};