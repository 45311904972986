import NavbarMobile from './NavbarMobile';
import Sidenav from './Sidenav';
import Footer from './Footer';

const Layout = ({children}) =>{

    return (
        <>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <Sidenav/>
                </div>
                <div id="layoutSidenav_content">
                    <div id="sidenavOverlay" className="d-none d-md-none"></div>
                    <main>
                        <NavbarMobile/>
                        {children}
                    </main>
                    <Footer/>
                </div>
            </div>
        </>
    )
}

export default Layout;