const countries = [
    {
      "countryNameEn": "Andorra",
      "countryCallingCode": "+376",
      "flag": "🇦🇩"
    },
    {
      "countryNameEn": "Afghanistan",
      "countryCallingCode": "+93",
      "flag": "🇦🇫"
    },
    {
      "countryNameEn": "Antigua and Barbuda",
      "countryCallingCode": "+1268",
      "flag": "🇦🇬"
    },
    {
      "countryNameEn": "Anguilla",
      "countryCallingCode": "+1264",
      "flag": "🇦🇮"
    },
    {
      "countryNameEn": "Albania",
      "countryCallingCode": "+355",
      "flag": "🇦🇱"
    },
    {
      "countryNameEn": "Armenia",
      "countryCallingCode": "+374",
      "flag": "🇦🇲"
    },
    {
      "countryNameEn": "Angola",
      "countryCallingCode": "+244",
      "flag": "🇦🇴"
    },
    {
      "countryNameEn": "Antarctica",
      "countryCallingCode": "+672",
      "flag": "🇦🇶"
    },
    {
      "countryNameEn": "Argentina",
      "countryCallingCode": "+54",
      "flag": "🇦🇷"
    },
    {
      "countryNameEn": "American Samoa",
      "countryCallingCode": "+1684",
      "flag": "🇦🇸"
    },
    {
      "countryNameEn": "Austria",
      "countryCallingCode": "+43",
      "flag": "🇦🇹"
    },
    {
      "countryNameEn": "Australia",
      "countryCallingCode": "+61",
      "flag": "🇦🇺"
    },
    {
      "countryNameEn": "Aruba",
      "countryCallingCode": "+297",
      "flag": "🇦🇼"
    },
    {
      "countryNameEn": "Åland Islands",
      "countryCallingCode": "+358",
      "flag": "🇦🇽"
    },
    {
      "countryNameEn": "Azerbaijan",
      "countryCallingCode": "+994",
      "flag": "🇦🇿"
    },
    {
      "countryNameEn": "Bosnia and Herzegovina",
      "countryCallingCode": "+387",
      "flag": "🇧🇦"
    },
    {
      "countryNameEn": "Barbados",
      "countryCallingCode": "+1246",
      "flag": "🇧🇧"
    },
    {
      "countryNameEn": "Bangladesh",
      "countryCallingCode": "+880",
      "flag": "🇧🇩"
    },
    {
      "countryNameEn": "Belgium",
      "countryCallingCode": "+32",
      "flag": "🇧🇪"
    },
    {
      "countryNameEn": "Burkina Faso",
      "countryCallingCode": "+226",
      "flag": "🇧🇫"
    },
    {
      "countryNameEn": "Bulgaria",
      "countryCallingCode": "+359",
      "flag": "🇧🇬"
    },
    {
      "countryNameEn": "Bahrain",
      "countryCallingCode": "+973",
      "flag": "🇧🇭"
    },
    {
      "countryNameEn": "Burundi",
      "countryCallingCode": "+257",
      "flag": "🇧🇮"
    },
    {
      "countryNameEn": "Benin",
      "countryCallingCode": "+229",
      "flag": "🇧🇯"
    },
    {
      "countryNameEn": "Saint Barthélemy",
      "countryCallingCode": "+590",
      "flag": "🇧🇱"
    },
    {
      "countryNameEn": "Bermuda",
      "countryCallingCode": "+1441",
      "flag": "🇧🇲"
    },
    {
      "countryNameEn": "Brunei Darussalam",
      "countryCallingCode": "+673",
      "flag": "🇧🇳"
    },
    {
      "countryNameEn": "Bolivia (Plurinational State of)",
      "countryCallingCode": "+591",
      "flag": "🇧🇴"
    },
    {
      "countryNameEn": "Bonaire, Sint Eustatius and Saba",
      "countryCallingCode": "+5997",
      "flag": "🇧🇶"
    },
    {
      "countryNameEn": "Brazil",
      "countryCallingCode": "+55",
      "flag": "🇧🇷"
    },
    {
      "countryNameEn": "Bhutan",
      "countryCallingCode": "+975",
      "flag": "🇧🇹"
    },
    {
      "countryNameEn": "Bouvet Island",
      "countryCallingCode": "+47",
      "flag": "🇧🇻"
    },
    {
      "countryNameEn": "Botswana",
      "countryCallingCode": "+267",
      "flag": "🇧🇼"
    },
    {
      "countryNameEn": "Belarus",
      "countryCallingCode": "+375",
      "flag": "🇧🇾"
    },
    {
      "countryNameEn": "Belize",
      "countryCallingCode": "+501",
      "flag": "🇧🇿"
    },
    {
      "countryNameEn": "Canada",
      "countryCallingCode": "+1",
      "flag": "🇨🇦"
    },
    {
      "countryNameEn": "Switzerland",
      "countryCallingCode": "+41",
      "flag": "🇨🇭"
    },
    {
      "countryNameEn": "Côte d'Ivoire",
      "countryCallingCode": "+225",
      "flag": "🇨🇮"
    },
    {
      "countryNameEn": "Chile",
      "countryCallingCode": "+56",
      "flag": "🇨🇱"
    },
    {
      "countryNameEn": "Cameroon",
      "countryCallingCode": "+237",
      "flag": "🇨🇲"
    },
    {
      "countryNameEn": "China",
      "countryCallingCode": "+86",
      "flag": "🇨🇳"
    },
    {
      "countryNameEn": "Colombia",
      "countryCallingCode": "+57",
      "flag": "🇨🇴"
    },
    {
      "countryNameEn": "Costa Rica",
      "countryCallingCode": "+506",
      "flag": "🇨🇷"
    },
    {
      "countryNameEn": "Cuba",
      "countryCallingCode": "+53",
      "flag": "🇨🇺"
    },
    {
      "countryNameEn": "Cabo Verde",
      "countryCallingCode": "+238",
      "flag": "🇨🇻"
    },
    {
      "countryNameEn": "Curaçao",
      "countryCallingCode": "+599",
      "flag": "🇨🇼"
    },
    {
      "countryNameEn": "Christmas Island",
      "countryCallingCode": "+61",
      "flag": "🇨🇽"
    },
    {
      "countryNameEn": "Cyprus",
      "countryCallingCode": "+357",
      "flag": "🇨🇾"
    },
    {
      "countryNameEn": "Germany",
      "countryCallingCode": "+49",
      "flag": "🇩🇪"
    },
    {
      "countryNameEn": "Djibouti",
      "countryCallingCode": "+253",
      "flag": "🇩🇯"
    },
    {
      "countryNameEn": "Denmark",
      "countryCallingCode": "+45",
      "flag": "🇩🇰"
    },
    {
      "countryNameEn": "Dominica",
      "countryCallingCode": "+767",
      "flag": "🇩🇲"
    },
    {
      "countryNameEn": "Algeria",
      "countryCallingCode": "+213",
      "flag": "🇩🇿"
    },
    {
      "countryNameEn": "Ecuador",
      "countryCallingCode": "+593",
      "flag": "🇪🇨"
    },
    {
      "countryNameEn": "Estonia",
      "countryCallingCode": "+372",
      "flag": "🇪🇪"
    },
    {
      "countryNameEn": "Egypt",
      "countryCallingCode": "+20",
      "flag": "🇪🇬"
    },
    {
      "countryNameEn": "Western Sahara",
      "countryCallingCode": "+212",
      "flag": "🇪🇭"
    },
    {
      "countryNameEn": "Eritrea",
      "countryCallingCode": "+291",
      "flag": "🇪🇷"
    },
    {
      "countryNameEn": "Spain",
      "countryCallingCode": "+34",
      "flag": "🇪🇸"
    },
    {
      "countryNameEn": "Ethiopia",
      "countryCallingCode": "+251",
      "flag": "🇪🇹"
    },
    {
      "countryNameEn": "Finland",
      "countryCallingCode": "+358",
      "flag": "🇫🇮"
    },
    {
      "countryNameEn": "Fiji",
      "countryCallingCode": "+679",
      "flag": "🇫🇯"
    },
    {
      "countryNameEn": "Micronesia (Federated States of)",
      "countryCallingCode": "+691",
      "flag": "🇫🇲"
    },
    {
      "countryNameEn": "France",
      "countryCallingCode": "+33",
      "flag": "🇫🇷"
    },
    {
      "countryNameEn": "Gabon",
      "countryCallingCode": "+241",
      "flag": "🇬🇦"
    },
    {
      "countryNameEn": "Grenada",
      "countryCallingCode": "+1473",
      "flag": "🇬🇩"
    },
    {
      "countryNameEn": "Georgia",
      "countryCallingCode": "+995",
      "flag": "🇬🇪"
    },
    {
      "countryNameEn": "French Guiana",
      "countryCallingCode": "+594",
      "flag": "🇬🇫"
    },
    {
      "countryNameEn": "Guernsey",
      "countryCallingCode": "+44",
      "flag": "🇬🇬"
    },
    {
      "countryNameEn": "Ghana",
      "countryCallingCode": "+233",
      "flag": "🇬🇭"
    },
    {
      "countryNameEn": "Gibraltar",
      "countryCallingCode": "+350",
      "flag": "🇬🇮"
    },
    {
      "countryNameEn": "Greenland",
      "countryCallingCode": "+299",
      "flag": "🇬🇱"
    },
    {
      "countryNameEn": "Guinea",
      "countryCallingCode": "+224",
      "flag": "🇬🇳"
    },
    {
      "countryNameEn": "Guadeloupe",
      "countryCallingCode": "+590",
      "flag": "🇬🇵"
    },
    {
      "countryNameEn": "Equatorial Guinea",
      "countryCallingCode": "+240",
      "flag": "🇬🇶"
    },
    {
      "countryNameEn": "Greece",
      "countryCallingCode": "+30",
      "flag": "🇬🇷"
    },
    {
      "countryNameEn": "South Georgia and the South Sandwich Islands",
      "countryCallingCode": "+500",
      "flag": "🇬🇸"
    },
    {
      "countryNameEn": "Guatemala",
      "countryCallingCode": "+502",
      "flag": "🇬🇹"
    },
    {
      "countryNameEn": "Guam",
      "countryCallingCode": "+1",
      "flag": "🇬🇺"
    },
    {
      "countryNameEn": "Guinea-Bissau",
      "countryCallingCode": "+245",
      "flag": "🇬🇼"
    },
    {
      "countryNameEn": "Guyana",
      "countryCallingCode": "+592",
      "flag": "🇬🇾"
    },
    {
      "countryNameEn": "Hong Kong",
      "countryCallingCode": "+852",
      "flag": "🇭🇰"
    },
    {
      "countryNameEn": "Honduras",
      "countryCallingCode": "+504",
      "flag": "🇭🇳"
    },
    {
      "countryNameEn": "Croatia",
      "countryCallingCode": "+385",
      "flag": "🇭🇷"
    },
    {
      "countryNameEn": "Haiti",
      "countryCallingCode": "+509",
      "flag": "🇭🇹"
    },
    {
      "countryNameEn": "Hungary",
      "countryCallingCode": "+36",
      "flag": "🇭🇺"
    },
    {
      "countryNameEn": "Indonesia",
      "countryCallingCode": "+62",
      "flag": "🇮🇩"
    },
    {
      "countryNameEn": "Ireland",
      "countryCallingCode": "+353",
      "flag": "🇮🇪"
    },
    {
      "countryNameEn": "Israel",
      "countryCallingCode": "+972",
      "flag": "🇮🇱"
    },
    {
      "countryNameEn": "Isle of Man",
      "countryCallingCode": "+44",
      "flag": "🇮🇲"
    },
    {
      "countryNameEn": "India",
      "countryCallingCode": "+91",
      "flag": "🇮🇳"
    },
    {
      "countryNameEn": "British Indian Ocean Territories",
      "countryCallingCode": "+246",
      "flag": "🇮🇴",
    },
    {
      "countryNameEn": "Iraq",
      "countryCallingCode": "+964",
      "flag": "🇮🇶"
    },
    {
      "countryNameEn": "Iran (Islamic Republic of)",
      "countryCallingCode": "+98",
      "flag": "🇮🇷"
    },
    {
      "countryNameEn": "Iceland",
      "countryCallingCode": "+354",
      "flag": "🇮🇸"
    },
    {
      "countryNameEn": "Italy",
      "countryCallingCode": "+39",
      "flag": "🇮🇹"
    },
    {
      "countryNameEn": "Jersey",
      "countryCallingCode": "+44",
      "flag": "🇯🇪"
    },
    {
      "countryNameEn": "Jamaica",
      "countryCallingCode": "+876",
      "flag": "🇯🇲"
    },
    {
      "countryNameEn": "Jordan",
      "countryCallingCode": "+962",
      "flag": "🇯🇴"
    },
    {
      "countryNameEn": "Japan",
      "countryCallingCode": "+81",
      "flag": "🇯🇵"
    },
    {
      "countryNameEn": "Kenya",
      "countryCallingCode": "+254",
      "flag": "🇰🇪"
    },
    {
      "countryNameEn": "Kyrgyzstan",
      "countryCallingCode": "+996",
      "flag": "🇰🇬"
    },
    {
      "countryNameEn": "Cambodia",
      "countryCallingCode": "+855",
      "flag": "🇰🇭"
    },
    {
      "countryNameEn": "North Korea",
      "countryCallingCode": "+850",
      "flag": "🇰🇵"
    },
    {
      "countryNameEn": "South Korea",
      "countryCallingCode": "+82",
      "flag": "🇰🇷"
    },
    {
      "countryNameEn": "Kiribati",
      "countryCallingCode": "+686",
      "flag": "🇰🇮"
    },
    {
      "countryNameEn": "Saint Kitts and Nevis",
      "countryCallingCode": "+1869",
      "flag": "🇰🇳"
    },
    {
      "countryNameEn": "Kuwait",
      "countryCallingCode": "+965",
      "flag": "🇰🇼"
    },
    {
      "countryNameEn": "Kazakhstan",
      "countryCallingCode": "+7",
      "flag": "🇰🇿"
    },
    {
      "countryNameEn": "Lebanon",
      "countryCallingCode": "+961",
      "flag": "🇱🇧"
    },
    {
      "countryNameEn": "Saint Lucia",
      "countryCallingCode": "+1758",
      "flag": "🇱🇨"
    },
    {
      "countryNameEn": "Liechtenstein",
      "countryCallingCode": "+423",
      "flag": "🇱🇮"
    },
    {
      "countryNameEn": "Sri Lanka",
      "countryCallingCode": "+94",
      "flag": "🇱🇰"
    },
    {
      "countryNameEn": "Liberia",
      "countryCallingCode": "+231",
      "flag": "🇱🇷"
    },
    {
      "countryNameEn": "Lesotho",
      "countryCallingCode": "+266",
      "flag": "🇱🇸"
    },
    {
      "countryNameEn": "Lithuania",
      "countryCallingCode": "+370",
      "flag": "🇱🇹"
    },
    {
      "countryNameEn": "Luxembourg",
      "countryCallingCode": "+352",
      "flag": "🇱🇺"
    },
    {
      "countryNameEn": "Latvia",
      "countryCallingCode": "+371",
      "flag": "🇱🇻"
    },
    {
      "countryNameEn": "Libya",
      "countryCallingCode": "+218",
      "flag": "🇱🇾"
    },
    {
      "countryNameEn": "Morocco",
      "countryCallingCode": "+212",
      "flag": "🇲🇦"
    },
    {
      "countryNameEn": "Monaco",
      "countryCallingCode": "+377",
      "flag": "🇲🇨"
    },
    {
      "countryNameEn": "Montenegro",
      "countryCallingCode": "+382",
      "flag": "🇲🇪"
    },
    {
      "countryNameEn": "Saint Martin (French part)",
      "countryCallingCode": "+590",
      "flag": "🇲🇫"
    },
    {
      "countryNameEn": "Madagascar",
      "countryCallingCode": "+261",
      "flag": "🇲🇬"
    },
    {
      "countryNameEn": "Mali",
      "countryCallingCode": "+223",
      "flag": "🇲🇱"
    },
    {
      "countryNameEn": "Myanmar",
      "countryCallingCode": "+95",
      "flag": "🇲🇲"
    },
    {
      "countryNameEn": "Mongolia",
      "countryCallingCode": "+976",
      "flag": "🇲🇳"
    },
    {
      "countryNameEn": "Macao",
      "countryCallingCode": "+853",
      "flag": "🇲🇴"
    },
    {
      "countryNameEn": "Martinique",
      "countryCallingCode": "+596",
      "flag": "🇲🇶"
    },
    {
      "countryNameEn": "Mauritania",
      "countryCallingCode": "+222",
      "flag": "🇲🇷"
    },
    {
      "countryNameEn": "Montserrat",
      "countryCallingCode": "+1664",
      "flag": "🇲🇸"
    },
    {
      "countryNameEn": "Malta",
      "countryCallingCode": "+356",
      "flag": "🇲🇹"
    },
    {
      "countryNameEn": "Mauritius",
      "countryCallingCode": "+230",
      "flag": "🇲🇺"
    },
    {
      "countryNameEn": "Maldives",
      "countryCallingCode": "+960",
      "flag": "🇲🇻"
    },
    {
      "countryNameEn": "Malawi",
      "countryCallingCode": "+265",
      "flag": "🇲🇼"
    },
    {
      "countryNameEn": "Mexico",
      "countryCallingCode": "+52",
      "flag": "🇲🇽"
    },
    {
      "countryNameEn": "Malaysia",
      "countryCallingCode": "+60",
      "flag": "🇲🇾"
    },
    {
      "countryNameEn": "Mozambique",
      "countryCallingCode": "+258",
      "flag": "🇲🇿"
    },
    {
      "countryNameEn": "Namibia",
      "countryCallingCode": "+264",
      "flag": "🇳🇦"
    },
    {
      "countryNameEn": "New Caledonia",
      "countryCallingCode": "+687",
      "flag": "🇳🇨"
    },
    {
      "countryNameEn": "Norfolk Island",
      "countryCallingCode": "+672",
      "flag": "🇳🇫"
    },
    {
      "countryNameEn": "Nigeria",
      "countryCallingCode": "+234",
      "flag": "🇳🇬"
    },
    {
      "countryNameEn": "Nicaragua",
      "countryCallingCode": "+505",
      "flag": "🇳🇮"
    },
    {
      "countryNameEn": "Norway",
      "countryCallingCode": "+47",
      "flag": "🇳🇴"
    },
    {
      "countryNameEn": "Nepal",
      "countryCallingCode": "+977",
      "flag": "🇳🇵"
    },
    {
      "countryNameEn": "Nauru",
      "countryCallingCode": "+674",
      "flag": "🇳🇷"
    },
    {
      "countryNameEn": "Niue",
      "countryCallingCode": "+683",
      "flag": "🇳🇺"
    },
    {
      "countryNameEn": "New Zealand",
      "countryCallingCode": "+64",
      "flag": "🇳🇿"
    },
    {
      "countryNameEn": "Oman",
      "countryCallingCode": "+968",
      "flag": "🇴🇲"
    },
    {
      "countryNameEn": "Panama",
      "countryCallingCode": "+507",
      "flag": "🇵🇦"
    },
    {
      "countryNameEn": "Peru",
      "countryCallingCode": "+51",
      "flag": "🇵🇪"
    },
    {
      "countryNameEn": "French Polynesia",
      "countryCallingCode": "+689",
      "flag": "🇵🇫"
    },
    {
      "countryNameEn": "Papua New Guinea",
      "countryCallingCode": "+675",
      "flag": "🇵🇬"
    },
    {
      "countryNameEn": "Pakistan",
      "countryCallingCode": "+92",
      "flag": "🇵🇰"
    },
    {
      "countryNameEn": "Poland",
      "countryCallingCode": "+48",
      "flag": "🇵🇱"
    },
    {
      "countryNameEn": "Saint Pierre and Miquelon",
      "countryCallingCode": "+508",
      "flag": "🇵🇲"
    },
    {
      "countryNameEn": "Pitcairn",
      "countryCallingCode": "+64",
      "flag": "🇵🇳"
    },
    {
      "countryNameEn": "Puerto Rico",
      "countryCallingCode": "+1",
      "flag": "🇵🇷"
    },
    {
      "countryNameEn": "Palestine, State of",
      "countryCallingCode": "+970",
      "flag": "🇵🇸"
    },
    {
      "countryNameEn": "Portugal",
      "countryCallingCode": "+351",
      "flag": "🇵🇹"
    },
    {
      "countryNameEn": "Palau",
      "countryCallingCode": "+680",
      "flag": "🇵🇼"
    },
    {
      "countryNameEn": "Paraguay",
      "countryCallingCode": "+595",
      "flag": "🇵🇾"
    },
    {
      "countryNameEn": "Qatar",
      "countryCallingCode": "+974",
      "flag": "🇶🇦"
    },
    {
      "countryNameEn": "Réunion",
      "countryCallingCode": "+262",
      "flag": "🇷🇪"
    },
    {
      "countryNameEn": "Romania",
      "countryCallingCode": "+40",
      "flag": "🇷🇴"
    },
    {
      "countryNameEn": "Serbia",
      "countryCallingCode": "+381",
      "flag": "🇷🇸"
    },
    {
      "countryNameEn": "Russia",
      "countryCallingCode": "+7",
      "flag": "🇷🇺"
    },
    {
      "countryNameEn": "Rwanda",
      "countryCallingCode": "+250",
      "flag": "🇷🇼"
    },
    {
      "countryNameEn": "Saudi Arabia",
      "countryCallingCode": "+966",
      "flag": "🇸🇦"
    },
    {
      "countryNameEn": "Solomon Islands",
      "countryCallingCode": "+677",
      "flag": "🇸🇧"
    },
    {
      "countryNameEn": "Seychelles",
      "countryCallingCode": "+248",
      "flag": "🇸🇨"
    },
    {
      "countryNameEn": "Sweden",
      "countryCallingCode": "+46",
      "flag": "🇸🇪"
    },
    {
      "countryNameEn": "Singapore",
      "countryCallingCode": "+65",
      "flag": "🇸🇬"
    },
    {
      "countryNameEn": "Saint Helena, Ascension and Tristan da Cunha",
      "countryCallingCode": "+290",
      "flag": "🇸🇭"
    },
    {
      "countryNameEn": "Slovenia",
      "countryCallingCode": "+386",
      "flag": "🇸🇮"
    },
    {
      "countryNameEn": "Svalbard and Jan Mayen",
      "countryCallingCode": "+4779",
      "flag": "🇸🇯"
    },
    {
      "countryNameEn": "Slovakia",
      "countryCallingCode": "+421",
      "flag": "🇸🇰"
    },
    {
      "countryNameEn": "Sierra Leone",
      "countryCallingCode": "+232",
      "flag": "🇸🇱"
    },
    {
      "countryNameEn": "Republic of San Marino",
      "countryCallingCode": "+378",
      "flag": "🇸🇲"
    },
    {
      "countryNameEn": "Senegal",
      "countryCallingCode": "+221",
      "flag": "🇸🇳"
    },
    {
      "countryNameEn": "Somalia",
      "countryCallingCode": "+252",
      "flag": "🇸🇴"
    },
    {
      "countryNameEn": "Suriname",
      "countryCallingCode": "+597",
      "flag": "🇸🇷"
    },
    {
      "countryNameEn": "South Sudan",
      "countryCallingCode": "+211",
      "flag": "🇸🇸"
    },
    {
      "countryNameEn": "Sao Tome and Principe",
      "countryCallingCode": "+239",
      "flag": "🇸🇹"
    },
    {
      "countryNameEn": "El Salvador",
      "countryCallingCode": "+503",
      "flag": "🇸🇻"
    },
    {
      "countryNameEn": "Sint Maarten (Dutch part)",
      "countryCallingCode": "+1721",
      "flag": "🇸🇽"
    },
    {
      "countryNameEn": "Syrian Arab Republic",
      "countryCallingCode": "+963",
      "flag": "🇸🇾"
    },
    {
      "countryNameEn": "Chad",
      "countryCallingCode": "+235",
      "flag": "🇹🇩"
    },
    {
      "countryNameEn": "Togo",
      "countryCallingCode": "+228",
      "flag": "🇹🇬"
    },
    {
      "countryNameEn": "Thailand",
      "countryCallingCode": "+66",
      "flag": "🇹🇭"
    },
    {
      "countryNameEn": "Tajikistan",
      "countryCallingCode": "+992",
      "flag": "🇹🇯"
    },
    {
      "countryNameEn": "Tokelau",
      "countryCallingCode": "+690",
      "flag": "🇹🇰"
    },
    {
      "countryNameEn": "Timor-Leste",
      "countryCallingCode": "+670",
      "flag": "🇹🇱"
    },
    {
      "countryNameEn": "Turkmenistan",
      "countryCallingCode": "+993",
      "flag": "🇹🇲"
    },
    {
      "countryNameEn": "Tunisia",
      "countryCallingCode": "+216",
      "flag": "🇹🇳"
    },
    {
      "countryNameEn": "Tonga",
      "countryCallingCode": "+676",
      "flag": "🇹🇴"
    },
    {
      "countryNameEn": "Turkey",
      "countryCallingCode": "+90",
      "flag": "🇹🇷"
    },
    {
      "countryNameEn": "Trinidad and Tobago",
      "countryCallingCode": "+868",
      "flag": "🇹🇹"
    },
    {
      "countryNameEn": "Tuvalu",
      "countryCallingCode": "+688",
      "flag": "🇹🇻"
    },
    {
      "countryNameEn": "United Republic of Tanzania",
      "countryCallingCode": "+255",
      "flag": "🇹🇿"
    },
    {
      "countryNameEn": "Ukraine",
      "countryCallingCode": "+380",
      "flag": "🇺🇦"
    },
    {
      "countryNameEn": "Uganda",
      "countryCallingCode": "+256",
      "flag": "🇺🇬"
    },
    {
      "countryNameEn": "United States of America",
      "countryCallingCode": "+1",
      "flag": "🇺🇸"
    },
    {
      "countryNameEn": "Uruguay",
      "countryCallingCode": "+598",
      "flag": "🇺🇾"
    },
    {
      "countryNameEn": "Uzbekistan",
      "countryCallingCode": "+998",
      "flag": "🇺🇿"
    },
    {
      "countryNameEn": "Saint Vincent and the Grenadines",
      "countryCallingCode": "+1784",
      "flag": "🇻🇨"
    },
    {
      "countryNameEn": "Venezuela (Bolivarian Republic of)",
      "countryCallingCode": "+58",
      "flag": "🇻🇪"
    },
    {
      "countryNameEn": "Virgin Islands (British)",
      "countryCallingCode": "+1284",
      "flag": "🇻🇬"
    },
    {
      "countryNameEn": "Virgin Islands (U.S.)",
      "countryCallingCode": "+1340",
      "flag": "🇻🇮"
    },
    {
      "countryNameEn": "Vietnam",
      "countryCallingCode": "+84",
      "flag": "🇻🇳"
    },
    {
      "countryNameEn": "Vanuatu",
      "countryCallingCode": "+678",
      "flag": "🇻🇺"
    },
    {
      "countryNameEn": "Wallis and Futuna",
      "countryCallingCode": "+681",
      "flag": "🇼🇫"
    },
    {
      "countryNameEn": "Samoa",
      "countryCallingCode": "+685",
      "flag": "🇼🇸"
    },
    {
      "countryNameEn": "Yemen",
      "countryCallingCode": "+967",
      "flag": "🇾🇪"
    },
    {
      "countryNameEn": "Mayotte",
      "countryCallingCode": "+262",
      "flag": "🇾🇹"
    },
    {
      "countryNameEn": "South Africa",
      "countryCallingCode": "+27",
      "flag": "🇿🇦"
    },
    {
      "countryNameEn": "Zambia",
      "countryCallingCode": "+260",
      "flag": "🇿🇲"
    },
    {
      "countryNameEn": "Zimbabwe",
      "countryCallingCode": "+263",
      "flag": "🇿🇼"
    },
    {
      "countryNameEn": "Eswatini",
      "countryCallingCode": "+268",
      "flag": "🇸🇿"
    },
    {
      "countryNameEn": "North Macedonia",
      "countryCallingCode": "+389",
      "flag": "🇲🇰"
    },
    {
      "countryNameEn": "Philippines",
      "countryCallingCode": "+63",
      "flag": "🇵🇭"
    },
    {
      "countryNameEn": "Netherlands",
      "countryCallingCode": "+31",
      "flag": "🇳🇱"
    },
    {
      "countryNameEn": "United Arab Emirates",
      "countryCallingCode": "+971",
      "flag": "🇦🇪"
    },
    {
      "countryNameEn": "Republic of Moldova",
      "countryCallingCode": "+373",
      "flag": "🇲🇩"
    },
    {
      "countryNameEn": "Gambia",
      "countryCallingCode": "+220",
      "flag": "🇬🇲"
    },
    {
      "countryNameEn": "Dominican Republic",
      "countryCallingCode": "+1",
      "flag": "🇩🇴"
    },
    {
      "countryNameEn": "Sudan",
      "countryCallingCode": "+249",
      "flag": "🇸🇩"
    },
    {
      "countryNameEn": "Lao People's Democratic Republic",
      "countryCallingCode": "+856",
      "flag": "🇱🇦"
    },
    {
      "countryNameEn": "Taiwan, Province of China",
      "countryCallingCode": "+886",
      "flag": "🇹🇼"
    },
    {
      "countryNameEn": "Republic of the Congo",
      "countryCallingCode": "+242",
      "flag": "🇨🇬"
    },
    {
      "countryNameEn": "Czechia",
      "countryCallingCode": "+420",
      "flag": "🇨🇿"
    },
    {
      "countryNameEn": "United Kingdom",
      "countryCallingCode": "+44",
      "flag": "🇬🇧"
    },
    {
      "countryNameEn": "Niger",
      "countryCallingCode": "+227",
      "flag": "🇳🇪"
    },
    {
      "countryNameEn": "Democratic Republic of the Congo",
      "countryCallingCode": "+243",
      "flag": "🇨🇩",
    },
    {
      "countryNameEn": "Commonwealth of The Bahamas",
      "countryCallingCode": "+1242",
      "flag": "🇧🇸",
    },
    {
      "countryNameEn": "Cocos (Keeling) Islands",
      "countryCallingCode": "+61891",
      "flag": "🇨🇨",
    },
    {
      "countryNameEn": "Central African Republic",
      "countryCallingCode": "+236",
      "flag": "🇨🇫",
    },
    {
      "countryNameEn": "Cook Islands",
      "countryCallingCode": "+682",
      "flag": "🇨🇰",
    },
    {
      "countryNameEn": "Falkland Islands",
      "countryCallingCode": "+500",
      "flag": "🇫🇰",
    },
    {
      "countryNameEn": "Faroe Islands",
      "countryCallingCode": "+298",
      "flag": "🇫🇴",
    },
    {
      "countryNameEn": "Territory of Heard Island and McDonald Islands",
      "countryCallingCode": "+672",
      "flag": "🇭🇲",
    },
    {
      "countryNameEn": "British Indian Ocean Territory",
      "countryCallingCode": "+246",
      "flag": "🇮🇴",
    },
    {
      "countryNameEn": "Comoros",
      "countryCallingCode": "+269",
      "flag": "🇰🇲",
    },
    {
      "countryNameEn": "Cayman Islands",
      "countryCallingCode": "+1345",
      "flag": "🇰🇾",
    },
    {
      "countryNameEn": "Republic of the Marshall Islands",
      "countryCallingCode": "+692",
      "flag": "🇲🇭",
    },
    {
      "countryNameEn": "Commonwealth of the Northern Mariana Islands",
      "countryCallingCode": "+1670",
      "flag": "🇲🇵",
    },
    {
      "countryNameEn": "Turks and Caicos Islands",
      "countryCallingCode": "+1649",
      "flag": "🇹🇨",
    },
    {
      "countryNameEn": "French Southern and Antarctic Lands",
      "countryCallingCode": "+672",
      "flag": "🇹🇫",
    },
    {
      "countryNameEn": "United States Minor Outlying Islands",
      "countryCallingCode": "+1",
      "flag": "🇺🇲",
    },
    {
      "countryNameEn": "Holy See",
      "countryCallingCode": "+39",
      "flag": "🇻🇦",
    },
    {
      "countryNameEn": "Republic of Kosovo",
      "countryCallingCode": "+383",
      "flag": "🇽🇰",
    },
    {
      "countryNameEn": "Netherlands Antilles",
      "countryCallingCode": "+599",
      "flag": "🇧🇶",
    },
  ]
  
  export default countries;