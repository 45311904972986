import { Row, Card, Col } from 'react-bootstrap';
import { useAuth } from '../firebase/auth';

export default function Account() {

    const { authUser } = useAuth();

    return (
        <Row>
            <Col className='mb-3'>
                <Card>
                    <Card.Header>Account Details</Card.Header>
                    <Card.Body>
                        <h5 className='card-title'>Name</h5>
                        <p>{authUser.displayName}</p>
                        <h5 className='card-title'>Email</h5>
                        <p>{authUser.email}</p>                                                                
                    </Card.Body>
                </Card>
            </Col>
            <Col className='mb-3'>
                <Card>
                    <Card.Header>Company Details</Card.Header>
                    <Card.Body>       
                        <h5 className='card-title'>Name</h5>
                        <p>{authUser.company.name}</p>
                        <h5 className='card-title'>Email</h5>
                        <p>{authUser.company.email}</p>
                        <h5 className='card-title'>Phone</h5>
                        <p>{authUser.company.phone}</p>
                        <h5 className='card-title'>Address</h5>
                        <p>{authUser.company.address}</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};