import Page from '../../layout/Page';
import { Row, Col, Button } from 'react-bootstrap';
import { useAuth } from '../../firebase/auth';
import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import TypeBadge from '../../components/TypeBadge';
import DataTable from '../../components/DataTable';


export default function Transactions() {


    const { authUser } = useAuth();
    const [transactions, setTransactions] = useState(null);

    useEffect(() => {
        if (authUser) {
            const transactionsRef = collection(db, 'companies', authUser.company.uid, 'transactions');
            const q = query(transactionsRef, orderBy('date', 'desc'));
            getDocs(q).then((snapshot) => {
                const updatedTransactions = [];
                snapshot.forEach((doc) => {
                    updatedTransactions.push({
                        uid: doc.id,
                        ...doc.data(),
                    });
                });
                setTransactions(updatedTransactions);
            });
        }
      }, [authUser]);

    const headerButtons = () => {
        return (
            <div className="col-auto">
                <Link to="../transactions/create">
                    <Button variant="primary" size="sm" className='d-inline-block'>
                        Create New
                    </Button>
                </Link>
            </div>
        );
    }
    
    const dataQuery = query(collection(db, 'companies', authUser.company.uid, 'transactions'), orderBy("createdAt", "desc"));
    const searchField = "keywords"
    const columns = ["Date","Customer","Type","Amount","Currency","Created By"];
    const pageSize = 5;
    const rowRenderer = (transaction) => {
        return (
            <tr key={transaction.uid} style={{ cursor: 'pointer' }}>
                <td>{format(transaction.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td>
                    <Link to={"../customers/"+transaction.customer}>
                        <p className="mb-0 fw-bold">{transaction.customerDetails.firstName} {transaction.customerDetails.lastName}</p>
                        <p className="mb-0 text-muted text-xs text-nowrap">ID {transaction.customer}</p>

                    </Link>
                </td>
                <td><TypeBadge>{transaction.type}</TypeBadge></td>
                <td>{transaction.amount}</td>
                <td>{transaction.currency}</td>
                <td>{transaction.createdByName}</td>
            </tr>
        );
    }

    return (
        <Page.AdminOrOfficer
            title="Transaction Monitoring (KYT)"
            description={"Monitoring"}
            headerContent={headerButtons()}
        >
            <Row>
                <Col>
                    <DataTable
                        tableTitle="Transactions"
                        dataQuery={dataQuery}
                        columns={columns}
                        rowRenderer={rowRenderer}
                        pageSize={pageSize}
                        searchField={searchField}
                    />
                </Col>
            </Row>
        </Page.AdminOrOfficer>
    );
};