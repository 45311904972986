import { useState } from "react";
import { Button, Card, Form, Row } from "react-bootstrap";
import { inviteMember } from "../../firebase/members";
import CustomModal from "../../components/CustomModal";

export default function MemberCreateForm() {

    const userRoles = [
        {
            name: "Compliance Officer",
            value: "officer"
        },
        {
            name: "Data Entry Clerk",
            value: "clerk"
        },
    ]

    const [member, setMember] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setMember({...member, [e.target.name]: e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = member;
        try{
            const result = await inviteMember(data);
            setIsLoading(false);
            if(result.success){
                setIsSent(true);
            }else{
                setError(result.message)
            }
            return result;
        }
        catch(error){
            setIsLoading(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Card>
                <Card.Header>
                    Create Invitation
                </Card.Header>
                <Card.Body>
                    <Row className="gx-3">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <Form.Label className="small mb-1">Email</Form.Label>
                                <Form.Control required className="form-control-solid" type="email" name="email" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <Form.Label className="small mb-1">User Role</Form.Label>
                                <Form.Select required className="form-control-solid" name="role" onChange={handleChange}>
                                    <option value="">Select a role</option>
                                    {userRoles.map((role, index) => (
                                        <option key={index} value={role.value}>{role.name}</option>
                                        ))}
                                </Form.Select>
                            </div>
                        </div>
                    </Row>
                    <Button type="submit" variant="primary">Submit</Button>
                </Card.Body>
            </Card>
            <CustomModal.Info show={isSent} close={() => setIsSent(false)} title="Invitation sent" message={"An invitation has been sent to "+ member.email + " for the role " + member.role} />
            <CustomModal.Loading show={isLoading}/>
            <CustomModal.Error show={error} close={() => setError(null)} message={error}/>
        </Form>
    );
}