import { Button, Spinner, Modal } from "react-bootstrap";

function CustomModal({show, onHide=null, children}){
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

function LoadingModal({show, title="Loading"}){
    return(
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Spinner variant="primary" animation="border" />
            </Modal.Body>
        </Modal>
    );
}

function InfoModal({show, title="Info", close, message}){
    return(
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
                <Button onClick={close}>Close</Button>
            </Modal.Body>
        </Modal>
    );
}

function ErrorModal({show, close, message}){
    return(
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
                <Button onClick={close}>Close</Button>
            </Modal.Body>
        </Modal>
    );
}

function DeleteModal({show, close, title="Delete", message, handleDelete}){
    return(
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-primary-soft text-primary" onClick={close}>
                    Cancel
                </Button>
                <Button className="btn-danger-soft text-danger" onClick={handleDelete}>
                    Yes, Delete
                </Button>            
            </Modal.Footer>
        </Modal>
    );
}

CustomModal.Error = ErrorModal;
CustomModal.Info = InfoModal;
CustomModal.Loading = LoadingModal;
CustomModal.Delete = DeleteModal;

export default CustomModal;