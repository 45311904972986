import { Navigate } from "react-router-dom";
import Header from "./Header";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../firebase/auth";

// Base Page component
function Page({ children, title, description, compact=false, header=true, headerContent=null }) {
  return (
    <>
      { header &&
        <Header title={title} description={description} compact={compact} headerContent={headerContent ? headerContent : ""}/>
      }
      <div className="container">
        {children}
      </div>
    </>
  );
}

// Variation for a admin only Page
function AdminPage({ children, title, description, header=true, compact=false, headerContent=null }) {
    const { authUser, isLoading } = useAuth();
  
    return (
        isLoading ?
        <Spinner animation="border" variant="primary" />
        :
        (authUser.role === "admin") ?
        <Page title={title} description={description} compact={compact} header={header} headerContent={headerContent}>
            {children}
        </Page>
        :
        <Navigate to='/dashboard' />
    );
}

// Variation for a admin only Page
function AdminOrOfficerPage({ children, title, description, header=true, compact=false, headerContent=null }) {
    const { authUser, isLoading } = useAuth();
  
    return (
        isLoading ?
        <Spinner animation="border" variant="primary" />
        :
        (authUser.role === "admin" || authUser.role === "officer") ?
        <Page title={title} description={description} compact={compact} header={header} headerContent={headerContent}>
            {children}
        </Page>
        :
        <Navigate to='/dashboard' />
    );
}

Page.Admin = AdminPage;
Page.AdminOrOfficer = AdminOrOfficerPage;

export default Page;
