import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom';


export default function SidenavLink({children, icon, text, link, isEnd=false}) {
    if (children) {
        return (
        <>
            <NavLink className="nav-link collapsed" to={link} end={isEnd} data-bs-toggle="collapse" data-bs-target={"#collapse" + text}>
                <div className="nav-link-icon">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                {text}
                <div className="sidenav-collapse-arrow">
                    <FontAwesomeIcon icon={faAngleDown}/>
                </div>
            </NavLink>
            <div className="collapse" id={"collapse" + text}>
                <nav className="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
                    {children}
                </nav>
            </div>
        </>
        );
    }
    else{
        return (
            <NavLink className="nav-link" to={link} end={isEnd}>
                <div className="nav-link-icon">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                {text}
            </NavLink>
        );
    }
}