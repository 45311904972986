import Page from '../../layout/Page';
import { Row, Col, Button, Card, Nav, Table, Stack, OverlayTrigger, Tooltip } from 'react-bootstrap'; // Assuming you have Card and Table components from react-bootstrap
import { useAuth } from '../../firebase/auth';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { doc, query, collection, where, updateDoc, getDocs } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareEnvelope, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import NoResultCard from '../../components/NoResultCard';
import TablePlaceholder from '../../components/TablePlaceholder';
import CustomModal from '../../components/CustomModal';
import { set } from 'date-fns';

const defaultFields = [
    {name: "First Name", type: "Text"},
    {name: "Last Name", type: "Text"},
    {name: "Date of Birth", type: "Date"},
    {name: "Nationality", type: "Text"},
    {name: "Phone", type: "Text"},
    {name: "Email", type: "Text"},
]

export default function Levels() {
    const { authUser } = useAuth();
    const [levels, setLevels] = useState(null);
    const [levelType, setLevelType] = useState("kyc");
    const [levelToDelete, setLevelToDelete] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        fetchLevels().then((levels) => {
            setLevels(levels);
        });
    }, []);

    const fetchLevels = async () => {
        const q = query(collection(db, "companies", authUser.company.uid, "levels"), where("isDeleted", "==", false));
        const querySnapshot = await getDocs(q);
        const levels = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
        }));
        return levels;
    }

    const deleteLevel = (levelId) => {
        const levelRef = doc(db, "companies", authUser.company.uid, "levels", levelId);
        updateDoc(levelRef, {
            isDeleted: true
        })
        .then(() => {
            fetchLevels().then((levels) => {
                setLevels(levels);
            });
        })
        .catch((error) => {
            alert("Error deleting level: ", error);
        });
    }

    const handleDeleteConfirmation = () => {
        deleteLevel(levelToDelete);
        setLevelToDelete();
    }

    return (
        <Page.AdminOrOfficer title="Levels" description="Settings">

            <Nav className="nav nav-borders">
                <Nav.Link className="nav-link" onClick={() => setLevelType("kyc")} active={levelType === "kyc"}>
                    Customers
                </Nav.Link>
                <Nav.Link className="nav-link" onClick={() => setLevelType("kyb")} active={levelType === "kyb"}>
                    Businesses
                </Nav.Link>
            </Nav>
            <hr className="mt-0 mb-4" />

            <Row>
                <Col>
                    <Card>
                        {
                        levels && levels.length === 0 ?
                            <NoResultCard
                                title="You don't have any levels yet"
                                description="Want to create a level? Click the button below to start now!"
                                link="./create"
                            />
                        :
                        <>
                        <Card.Header className='d-flex justify-content-between align-items-center'>
                            {levelType.toUpperCase() + " Levels"}
                            <Button onClick={()=>{
                                navigate("./create", 
                                    {relative: "path", state: {levelType: levelType}})
                                }}
                                size="sm"
                                variant="primary-soft text-primary">
                                Create New
                            </Button>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table responsive hover className="small">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Personal Information</th>
                                        <th>Documents</th>
                                        <th>Verifications</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    !levels &&
                                    <TablePlaceholder />
                                    }
                                    {levels && levels.length > 0 && levels.filter((level)=>level.type === levelType).map((level) => (
                                        <tr>
                                            <td>{level.name}</td>
                                            <td>
                                                <Col>
                                                    <ul className="p-0 m-0 list-unstyled">
                                                            {defaultFields.map((field) => (
                                                                    <li key={field} className="lh-sm">
                                                                    <span className="text-sm">
                                                                        {field.name}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                            {level.fields.map((field) => (
                                                                    <li key={field} className="lh-sm">
                                                                    <span className="text-sm">
                                                                        {field.name}
                                                                    </span>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </Col>
                                            </td>
                                            <td>
                                                <Col>
                                                    <ul className="p-0 m-0 list-unstyled">
                                                            {
                                                                level.documents.map((document) => (
                                                                    <li key={document} className="lh-sm">
                                                                        <span className="text-sm">
                                                                            {document}
                                                                        </span>
                                                                    </li>
                                                                ))
                                                            }
                                                    </ul>
                                                </Col>                                            
                                            </td>
                                            <td>
                                                <Stack direction="horizontal" gap={2}>
                                                    {level.verifications && level.verifications.map((verification) => (
                                                        <OverlayTrigger overlay={<Tooltip id={verification}>{verification}</Tooltip>}>
                                                            <FontAwesomeIcon icon={(verification === "SMS Verification" ? faSquarePhone : faSquareEnvelope)} className="text-primary fs-1"/>
                                                        </OverlayTrigger>
                                                    ))}
                                                </Stack>
                                            </td>
                                            <td>
                                                <Stack direction="horizontal" gap={2}>
                                                    <Link to={`./edit/${level.id}`}>
                                                        <Button size="sm" className="btn-primary-soft text-primary">Edit</Button>
                                                    </Link>
                                                    <Button size="sm" className="btn-danger-soft text-danger" onClick={()=>setLevelToDelete(level.id)}>Delete</Button>
                                                </Stack>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                        </>
                        }
                    </Card>
                </Col>
            </Row>
            <CustomModal.Delete
                show={levelToDelete}
                title="Confirm Deletion"
                message="Are you sure you want to delete this level?"
                onHide={() => setLevelToDelete()}
                handleDelete={handleDeleteConfirmation}
            />
        </Page.AdminOrOfficer>
    );
}
