import Page from '../../layout/Page';
import { Row } from 'react-bootstrap'; // Assuming you have Card and Table components from react-bootstrap
import LevelEditForm from '../../forms/levels/LevelEditForm';
import { useParams } from 'react-router-dom';

import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useEffect, useState } from 'react';
import { useAuth } from '../../firebase/auth';

export default function LevelsEdit() {

    const { authUser } = useAuth();
    const { levelId } = useParams();
    
    const [level, setLevel] = useState(null);

    useEffect(() => {
        const docRef = doc(db, "companies", authUser.company.uid, "levels", levelId);
        getDoc(docRef)
        .then((doc) => {
            if (doc.exists()) {
                setLevel({
                    id: doc.id,
                    ...doc.data()
                });
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [authUser, levelId]);

    return (
        <Page.AdminOrOfficer title="Edit Level" description="Levels">
            <Row>
                {level && <LevelEditForm level={level} />}
            </Row>
        </Page.AdminOrOfficer>
    );
}
