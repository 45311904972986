import { useEffect, useState } from "react";

export default function Activities({activity}){

    const [activities, setActivities] = useState(activity);

    const colors = {
        application: 'bg-primary',
        transaction: 'bg-secondary',
    }

    // Helper function to calculate the time passed and format it
    function formatTimePassed(activityDate) {
        const date = new Date(activityDate);
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 1) {
            return `${days} days`;
        } else if (days === 1) {
            return '1 day';
        } else if (hours > 1) {
            return `${hours} hours`;
        } else if (hours === 1) {
            return '1 hour';
        } else if (minutes > 1) {
            return `${minutes} min`;
        } else if (minutes === 1) {
            return '1 min';
        } else {
            return `${seconds} sec`;
        }
    }

    useEffect(() => {
        const sortedActivities = activity;
        if(sortedActivities && sortedActivities.length > 0){
            sortedActivities.reverse();
            setActivities(sortedActivities);
        }else{
            setActivities([]);
        }
    }, [activity]);


    return(
        <div className="card card-header-actions h-100">
            <div className="card-header">
                Recent Activity
            </div>
            <div className="card-body">
                <div className="timeline timeline-xs">
                    {activities && activities.length > 0 && activities.map((activity) => 
                        <div className="timeline-item">
                            <div className="timeline-item-marker">
                                <div className="timeline-item-marker-text">{formatTimePassed(activity.date.toDate())}</div>
                                <div className={"timeline-item-marker-indicator " + colors[activity.type]}></div>
                            </div>
                            <div className="timeline-item-content">
                                {activity.text}
                            </div>
                        </div>
                    )}
                    {activities && activities.length === 0 &&
                        <p>
                            No recent activity
                        </p>
                    }
                </div>
            </div>
        </div>
    );
}