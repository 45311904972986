import { Link, useParams } from "react-router-dom"
import { useState } from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { registerUser } from "../../firebase/register";

export default function MemberRegistrationForm(){

    const { invitationId } = useParams();

    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [member, setMember] = useState({});
    
    const handleChange = (e) => {
        if(error !== null) setError(null);
        setMember({...member, [e.target.name]: e.target.value});
    }

    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if(member.password !== member.passwordConfirm){
            setError("Passwords do not match");
            setIsLoading(false);
            return;
        }
        const data = {
            email: member.email,
            password: member.password,
            firstName: member.firstName,
            lastName: member.lastName,
            phone: member.phone,
            invitationCode: invitationId
        }
        
        const result = await registerUser(data);
        setMessage(result.message);
        setIsLoading(false);
        setIsCreated(true);
        console.log(result);
    }

    return(
        <Form>
            { error && <Alert variant="danger">{error}</Alert> }
            { message && <Alert variant="primary">{message}</Alert> }
            { !isCreated ?
            <>
            <Row className="gx-3">
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">First Name</Form.Label>
                        <Form.Control className="form-control-solid" type="text" name="firstName" onChange={handleChange} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Last Name</Form.Label>
                        <Form.Control className="form-control-solid" type="text" name="lastName" onChange={handleChange} />
                    </div>
                </div>
            </Row>
            <div className="mb-3">
                <Form.Label className="small mb-1">Email</Form.Label>
                <Form.Control className="form-control-solid" type="text" name="email" onChange={handleChange} />
            </div>
            <div className="mb-3">
                <Form.Label className="small mb-1">Phone Number</Form.Label>
                <Form.Control className="form-control-solid" type="text" name="phone" onChange={handleChange} />
            </div>
            <div className="row gx-3">
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Password</Form.Label>
                        <Form.Control className="form-control-solid" type="password" name="password" onChange={handleChange} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Confirm Password</Form.Label>
                        <Form.Control className="form-control-solid" type="password" name="passwordConfirm" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <Button type="submit" className="w-100" onClick={handleSignUp} disabled={isLoading}>Register</Button>
            </>
            :
            <Link to="/login">
                <Button variant="primary">Login</Button>
            </Link>
            }
        </Form>
    );
}