import { Card, Col, Row } from "react-bootstrap";

export default function CustomerInfoTable({ customer }) {

    function Title({ children }){
        return(
            <p className="text-muted mb-0">{ children }</p>
        );
    }

    function Value({ children }){
        return(
            <p className="text-body mb-2">{ children }</p>
        );
    }

    return (
        <Row>
            <Col sm={12} md={4}>
                <Title>First Name</Title>
                <Value>{customer.firstName}</Value>
                <Title>Last Name</Title>
                <Value>{customer.lastName}</Value>
            </Col>
            <Col sm={12} md={4}>
                <Title>Date of Birth</Title>
                <Value>{customer.dateofbirth}</Value>
                <Title>Phone</Title>
                <Value>{customer.phone}</Value>
            </Col>
            <Col sm={12} md={4}>
                <Title>Email</Title>
                <Value>{customer.email}</Value>
                <Title>Nationality</Title>
                <Value>{customer.nationality}</Value>
            </Col>
            <Col xs={12}>
            {
                customer.fields && customer.fields.map((field) => (
                    <Row key={field.name}>
                        <Title>{field.name}</Title>
                        <Value>{field.value}</Value>
                    </Row>
                )) 
            }
            </Col>
        </Row>
    );
}
