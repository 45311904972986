import { Spinner } from "react-bootstrap";
import { useAuth } from "../firebase/auth";
import { Navigate, Outlet } from "react-router-dom";


export default function PrivateRoute() {
    const { authUser, isLoading } = useAuth();
  
    return (
      isLoading ?
      <Spinner animation="border" variant="primary" />
      :
      (!authUser) ?
      <Navigate to='/login' />
      :
      <Outlet />
    );
  
  }