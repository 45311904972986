import { Form, Row, Col } from 'react-bootstrap';
import countries from '../utils/countries';

export default function CountryInput({name="nationality", onChange}) {

    const countryList = countries.sort((a, b) => a.countryNameEn.localeCompare(b.countryNameEn));

    return (
        <>
            <Form.Label>Nationality</Form.Label>
            <Row>
                <Col>
                    <Form.Select required name={name} className="form-control-solid" onChange={onChange}>
                        <option value="">Select Country</option>
                        {countryList.map((country, index) => (
                            <option key={index} value={country.countryNameEn}>{country.flag} {country.countryNameEn}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
        </>
    );
}
