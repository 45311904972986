import { Badge } from "react-bootstrap";

export default function StatusTag({status, soft=true}){

    const statusColors = {
        pending: 'warning',
        approved: 'success',
        rejected: 'danger',
    }

    const bg = soft ? 'bg-'+statusColors[status] : 'bg-'+statusColors[status]+'-text';
    const text = soft ? 'text-'+statusColors[status]+'-text' : "";
    const className = bg+' '+text;

    return(
        <Badge className={className} >{status.charAt(0).toUpperCase() + status.slice(1)}</Badge>
    );
}