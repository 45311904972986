import { useEffect } from 'react';
import { useAuth } from '../../firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import LayoutAuth from "../../layout/LayoutAuth";
import LoginForm from '../../forms/auth/LoginForm';
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function Login(){
    const { authUser, isLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && authUser) {
            navigate('/dashboard');
        }
    }, [authUser, isLoading, navigate])

    return(
        <LayoutAuth>
            <Col xs={12}>
                <h1 className="mb-0">Login</h1>
                <div className="small mb-3">
                    <Link to="/register">Need an account? Sign up!</Link>
                </div>
                <LoginForm/>
            </Col>
        </LayoutAuth>
    );
}