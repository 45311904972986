export const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
        }
    },
    scales: {
        x: {
            time: {
                unit: 'date'
            },
            border: {
                display: false,
            },
            grid: {
                display: false,
                drawBorder: false,
                color: "rgb(234, 236, 244)",
                tickLength: 0,
            },
            ticks: {
                font: {
                    size: 12,
                    family: "Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
                },
                color: "#858796",
                padding: 20,
            }
        },
        y: {
            min: 0,
            grid: {
                tickLength: 0,
                tickBorderDash: [2],
            },
            border: {
                display: false,
                dash: [2,2]
            },
            ticks: {
                display: false,
            }
        }
    },
    legend: {
        display: false
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            backgroundColor: "rgb(255,255,255)",
            bodyColor: "#858796",
            titleFont: {
                size: 14,
                family: "Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
            },
            bodyFont: {
                size: 14,
                family: "Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
            },
            titleMarginBottom: 10,
            titleColor: "#6e707e",
            borderColor: "#dddfeb",
            borderWidth: 1,
            padding : {
                x: 15,
                y: 15,
            },
            displayColors: false,
            intersect: false,
            mode: "index",
            caretPadding: 10,
        }
    },
};

export const data = {
    labels : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
        {
            label: "Verifications",
            data: [0,0,0,0,0,0,0],
            tension: 0.3,
            fill: {
                target: "origin",
                above: "rgba(0, 97, 242, 0.1)",
                below: "#000000"
              },
            hoverRadius: 3,
            borderColor: "#4e73df",
            backgroundColor: "rgba(0, 97, 242, 1)",
            hoverBorderColor: "rgba(0, 97, 242, 0.9)",
            maxBarThickness: 25
        },
    ],
};