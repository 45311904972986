import { Dropdown } from "react-bootstrap";

export default function RiskDropdown({risk, onSelect}){

    const colors = {
        low: "success",
        medium: "warning",
        high: "danger"
    }

    const riskText = {
        low: "Low",
        medium: "Medium",
        high: "High"
    }

    return(
        <Dropdown>
            {risk ? 
            <Dropdown.Toggle size="sm" variant={`${colors[risk]}-soft text-${colors[risk]}`} id="dropdown-basic">
                <span>Risk Level</span>
                <span className={`badge mx-2 bg-${colors[risk]}`}>{riskText[risk]}</span>
            </Dropdown.Toggle>
            :
            <Dropdown.Toggle size="sm" variant="light text-body" id="dropdown-basic">
                <span>Risk Level</span>
            </Dropdown.Toggle>
            }
            <Dropdown.Menu>
                <Dropdown.Item onClick={onSelect} name="low">Low</Dropdown.Item>
                <Dropdown.Item onClick={onSelect} name="medium">Medium</Dropdown.Item>
                <Dropdown.Item onClick={onSelect} name="high">High</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}