import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";

export default function LinkKYCAdditionalInformationForm({handleAdditionalInformation, level, isLoading}){

    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleAdditionalInformation(formData);
    }

    return(
        <Form onSubmit={handleSubmit}>
            <h1 className="card-title mb-4">Additional Information</h1>
            {
                level.fields.map((field) => (
                    <Form.Group key={field.name} className='mb-2'>
                        <Form.Label>{field.name}</Form.Label>
                        <Form.Control required className="form-control-solid" type="text" name={field.name} onChange={handleChange} />
                    </Form.Group>
                ))
            }
            <Button variant="primary" type="submit" disabled={isLoading} className="d-inline-block w-25 float-end mt-3">
                {isLoading && <Spinner variant="light" animation="border" size="sm" className="me-2" />}
                Next
            </Button>
        </Form>
    );
}
