import { faCircleCheck, faFingerprint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Spinner, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from '../../firebase/firebase';

import LinkKYCPersonalInformationForm from "../../forms/link/LinkKYCPersonalInformationForm";
import LinkKYCAdditionalInformationForm from "../../forms/link/LinkKYCAdditionalInformationForm";
import LinkKYCDocumentForm from "../../forms/link/LinkKYCDocumentForm";
import LinkKYCVerificationForm from "../../forms/link/LinkKYCVerificationForm";
import LinkKYCEmailVerificationForm from "../../forms/link/LinkKYCEmailVerificationForm";

let companyName;
let totalSteps;

export default function LinkKYC() {
    const { linkId } = useParams();
    const [level, setLevel] = useState(null);
    const [step, setStep] = useState("introduction");
    const [loading, setLoading] = useState(true);
    const [personalInformation, setPersonalInformation] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        setLoading(true);
        const docRef = doc(db, "links", linkId);
        getDoc(docRef).then((docSnap) => {
            totalSteps = ["introduction","personalInformation"];
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.expirationDate && data.expirationDate.toDate() < new Date()){
                    setLoading(false);
                    navigate('/');
                }
                if (data.level.fields && data.level.fields.length > 0) {
                    totalSteps.push("additionalInformation");
                }
                if (data.level.verifications && data.level.verifications.length > 0) {
                    totalSteps = totalSteps.concat(data.level.verifications);
                }
                if (data.level.documents && data.level.documents.length > 0) {
                    totalSteps.push("documents");
                }
                companyName = data.companyName;
                setLevel(data.level);
                setLoading(false);
            } else {
                setLoading(false);
                navigate('/');
            }
        });
    }, [linkId, navigate])

    const handlePersonalInformation = async (data) => {
        setIsLoading(true);
        setPersonalInformation(data);
        const nextStep = totalSteps[totalSteps.indexOf(step) + 1];
        if(nextStep !== "additionalInformation"){
            const result = await createCustomer(linkId, data);
            if(result && result.success){
                const nextStep = totalSteps[totalSteps.indexOf(step) + 1];
                setStep(nextStep);
                setIsLoading(false);
            }else{
                console.error(result);
                setIsLoading(false);
            }    
        }else{
            setStep(nextStep);
            setIsLoading(false);
        }
    }
    
    const handleVerification = () => {
        const nextStep = totalSteps[totalSteps.indexOf(step) + 1];
        setStep(nextStep);
    }
    
    const handleAdditionalInformation = async (data) => {
        setIsLoading(true);
        const result = await createCustomer(linkId, personalInformation, data);
        if(result && result.success){
            const nextStep = totalSteps[totalSteps.indexOf(step) + 1];
            setStep(nextStep);
            setIsLoading(false);
        }else{
            console.log(result);
            setIsLoading(false);
        }
    }

    const createCustomer = async (link, personalInformation, additionalInformation=null) => {
        let data = {
            link: link,
            personalInformation: [],
        }
        Object.entries(personalInformation).forEach(([key, value]) => data.personalInformation.push({name:key, value:value}));
        
        if(additionalInformation){
            data.additionalInformation = [];
            Object.entries(additionalInformation).forEach(([key, value]) => data.additionalInformation.push({name:key, value:value}));
        }

        data.status = {
            status: 'pending',
            fields: 'pending',
            documents: level.documents.reduce((acc, document) => ({ ...acc, [document]: "pending" }), {}),
        }
        try{
            const result = await fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/setPersonalInformation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            return await result.json();
        }catch(e){
            return null;
        }
    }

    const handleSubmit = async (event, data) => {
        event.preventDefault();
        setIsUploading(true);
        let formData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        formData.append('link', linkId);

        try{
            const result = await fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/uploadDocuments', {
                method: 'POST',
                body: formData
            })
            if(result.ok){
                setIsUploading(false);
                setIsUploaded(true);
            }
        }catch(e){
            console.log(e);
        }
    }

    function LinkKYCIntroduction(){
        return(
            <Col>
                <Col className="px-2 py-3">
                    <div className="d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faFingerprint} className="bg-primary text-white display-6 p-2 rounded" />
                        <span className="text-dark ms-2 fs-1">True<span className="fw-bold">KYC</span></span>
                    </div>
                </Col>
                <Card>
                    <Card.Body className="text-center text-md-start">
                        <h3 className="text-primary small">{companyName}</h3>
                        <h1 className="card-title">Verify Your Identity</h1>
                        <p className="text-body">We will get you through a simple process to verify your information.</p>
                        <p className="text-sm text-muted">You're about to submit sensitive data only to be shared with "{companyName}".</p>
                        <Button onClick={()=>setStep("personalInformation")}>Start Verification</Button>
                    </Card.Body>
                </Card>
            </Col>
        );
    }

    function LinkKYCFinal(){
        return(
            <Col>
            <Col className="px-2 py-3">
                <div className="d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faFingerprint} className="bg-primary text-white display-6 p-2 rounded" />
                    <span className="text-dark ms-2 fs-1">True<span className="fw-bold">KYC</span></span>
                </div>
            </Col>
            <Card>
                <Card.Body>
                    { isUploading && 
                    <>
                        <Spinner variant="primary" animation="border" className="mb-2" />
                        <h1 className="card-title">Uploading...</h1>
                        <p className="text-body">Please wait while your information is uploading</p>
                    </>
                    }
                    { isUploaded && 
                    <>
                        <FontAwesomeIcon icon={faCircleCheck} className="text-primary display-6 mb-2" />
                        <h1 className="card-title">Application Sent</h1>
                        <p className="text-body">We received your application. You will be verified after your information is confirmed.</p>
                    </>
                    }
                </Card.Body>
            </Card>
            </Col>
        );
    }
    
    return (
        <Container fluid className="px-0">
            {!loading && level &&
            <Col className="px-0 px-md-2 py-0 py-md-5 mx-auto" md={8} lg={6}>
                { step === "introduction" ? <LinkKYCIntroduction/> : 
                    (isUploading || isUploaded) ? <LinkKYCFinal/> :
                <Col>
                    <Col className="px-2 pt-3">
                        <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon={faFingerprint} className="bg-primary text-white display-6 p-2 rounded" />
                            <span className="text-dark ms-2 fs-1">True<span className="fw-bold">KYC</span></span>
                        </div>
                        <p className="text-center text-muted m-0 mt-2">{companyName} KYC Verification</p>
                    </Col>
                    <Col className="px-2 py-3 d-flex gap-3">
                        { totalSteps.slice(1).map((stepName, index) => 
                             <Col key={stepName} className="text-center">
                                <Badge bg={index + 1 <= totalSteps.indexOf(step)  ? "primary" : "gray-200"} className="w-100"> </Badge>
                            </Col>
                        )}
                    </Col>
                    <Card>
                        <Card.Body>
                            <h3 className="text-primary">Step {totalSteps.indexOf(step)}</h3>
                            { step === "personalInformation" && <LinkKYCPersonalInformationForm handlePersonalInformation={handlePersonalInformation} level={level} isLoading={isLoading}/> }
                            { step === "additionalInformation" && <LinkKYCAdditionalInformationForm handleAdditionalInformation={handleAdditionalInformation} level={level} isLoading={isLoading}/> }
                            { step === "SMS Verification" && <LinkKYCVerificationForm phoneNumber={personalInformation.phone} onComplete={handleVerification} isLoading={isLoading}/> }
                            { step === "E-mail Verification" && <LinkKYCEmailVerificationForm email={personalInformation.email} onComplete={handleVerification} isLoading={isLoading}/> }
                            { step === "documents" && <LinkKYCDocumentForm handleSubmit={handleSubmit} level={level} /> }
                        </Card.Body>
                        <Card.Footer>
                            <Stack direction="horizontal" className="justify-content-center">
                                <FontAwesomeIcon icon={faFingerprint} className="bg-gray-200 text-muted fs-4 p-2 rounded me-2" />
                                <p className="m-0 text-muted">Powered by TrueKYC</p>
                            </Stack>
                        </Card.Footer>
                    </Card>
                </Col>
                }
            </Col>
            }
        </Container>
       
    )
}