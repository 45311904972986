import Page from '../../layout/Page';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useAuth } from '../../firebase/auth';
import { Timestamp, addDoc, collection, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { format } from 'date-fns';
import StatusTag from '../../components/StatusTag';
import { useState } from 'react';
import LevelSelectForm from '../../forms/levels/LevelSelectForm';
import QRModal from '../../components/QRModal';
import RiskTag from '../../components/RiskTag';

export default function Customers() {

    const { authUser } = useAuth();

    const [showModal, setShowModal] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [link, setLink] = useState(null);
    const [levels, setLevels] = useState(null);

    const navigate = useNavigate();

    const navigateToCustomerDetails = (customerId) => {
        navigate("./"+customerId, { relative: "path"});
    }

    const handleClose = () => {
      setShowModal(false);
    }
    
    const handleShow = () => {
        setSelectedLevel();
        setShowModal(true)
    };

    const handleCreateLink = () => {
        const link = {
            level: {
                id: selectedLevel.id,
                name: selectedLevel.name,
                documents: selectedLevel.documents,
                fields: selectedLevel.fields,
                verifications: selectedLevel.verifications,
            },
            company: authUser.company.uid,
            companyName: authUser.company.name,
            expirationDate: new Date(Timestamp.now().seconds * 1000 + 86400000),
            createdBy: authUser.uid,
            createdByName: authUser.displayName
        }

        const linkRef = collection(db, 'links');
        addDoc(linkRef, link).then((docRef) => {
            setLink(window.location.origin + "/link/" + docRef.id);
            handleClose();
        }).catch((error) => {
            console.error("Error adding document: ", error);
        });
    }

    const headerButtons = () => {
        return (
            <div className="col-auto">
                <Button size="sm" onClick={handleShow} variant="primary">Create New</Button>
            </div>
        );
    }

    const dataQuery = query(collection(db, 'companies', authUser.company.uid, 'customers'), orderBy("updatedAt", "desc"));
    const searchField = "keywords"
    const columns = ["Customer","Level","Status","Risk","Tags","Updated At","Created At","Created By"];
    const pageSize = 5;
    const rowRenderer = (customer) => {
        return (
          <tr key={customer.uid} onClick={() => navigateToCustomerDetails(customer.uid)} style={{cursor: "pointer"}}>
            <td>
                <p className="mb-0 fw-bold text-primary">{customer.firstName} {customer.lastName}</p>
                <p className="mb-0 text-muted text-xs text-nowrap">ID {customer.uid}</p>
            </td>
            <td>{customer.level.name}</td>
            <td><StatusTag status={customer.status.status}/></td>
            <td><RiskTag risk={customer.status.risk}/></td>
            <td>
                { customer.status.tags && customer.status.tags.length >0 &&
                    customer.status.tags.map((tag) => (
                        <span key={tag} className="badge bg-danger-soft text-danger me-1">{tag}</span>
                    ))
                }
            </td>
            <td>{format(customer.createdAt.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
            <td>{format(customer.updatedAt.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
            <td>{customer.createdByName}</td>
          </tr>
        );
    };

    return (
        <Page.AdminOrOfficer 
            title="Customer Verification (KYC)" 
            description="Verifications"
            headerContent={headerButtons()}
        >
            <Row>
                <Col>
                    <DataTable
                        tableTitle="Customers"
                        dataQuery={dataQuery}
                        columns={columns}
                        rowRenderer={rowRenderer}
                        pageSize={pageSize}
                        searchField={searchField}
                    />
                </Col>
            </Row>
                <Modal key="levelSelect" show={showModal} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Customer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LevelSelectForm levels={levels} setLevels={setLevels} setSelectedLevel={setSelectedLevel}/>
                    {selectedLevel &&
                        <div className="mt-3">
                            <Button variant="primary w-100 mt-2" onClick={()=>{
                                navigate("./create", 
                                    {relative: "path", state: {level: selectedLevel}})
                                }}>
                                Create Customer
                            </Button>
                            <Button variant="primary-soft text-primary w-100 mt-2" onClick={handleCreateLink}>
                                Create Link
                            </Button>
                        </div>
                        }
                    </Modal.Body>
                </Modal>
                {link &&
                    <QRModal show={!!link} link={link} onClose={() => setLink(null)} />
                }
        </Page.AdminOrOfficer>
    );
};