import { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import {useAuth } from '../../firebase/auth';
import { Card, Col, Row } from 'react-bootstrap';
import { db } from '../../firebase/firebase';
import { collection, doc, getCountFromServer, getDoc, query, where } from 'firebase/firestore';
import StatCard from '../../components/StatCard';
import { faRightLeft, faUserCheck, faUserXmark, faUsers } from '@fortawesome/free-solid-svg-icons';
import { data, options } from '../../utils/charts/bar';
import { generateDateObject } from '../../utils/stats';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import Activities from '../../components/Activities';
  
ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement, BarElement,Title,Tooltip,Filler,Legend);

export default function Home() {

    const { authUser } = useAuth();
    const [applicationsData, setApplicationsData] = useState(data);
    const [transactionsData, setTransactionsData] = useState(data);
    const [customerCount, setCustomerCount] = useState(0);
    const [verifiedCustomerCount, setVerifiedCustomerCount] = useState(0);
    const [rejectedCustomerCount, setRejectedCustomerCount] = useState(0);
    const [transactionCount, setTransactionCount] = useState(0);
    const [activity, setActivity] = useState([]);        
    
    useEffect(() => {
        getDoc(doc(db, "companies", authUser.company.uid))
        .then((snapshot) => {
            const companyData = snapshot.data();

            let stats = companyData.stats ? companyData.stats : {};
            let applications = stats.applications ? stats.applications : {};
         
            const applicationsObj = generateDateObject();
            Object.keys(applicationsObj).forEach(key => {
                if (applications.hasOwnProperty(key)) {
                    applicationsObj[key] = applications[key];
                }
            });
            
            let sortedKeys = Object.keys(applicationsObj).sort((a, b) => new Date(a) - new Date(b));
            let sortedValues = sortedKeys.map(key => applicationsObj[key]);
            
            let updatedChartData = {
                ...data,
                labels: sortedKeys,
                datasets: [
                    {
                        ...data.datasets[0],
                        data: sortedValues,
                    },
                ],
            };
            setApplicationsData(updatedChartData);
                        
            let transactions = stats.transactions;
            if(!transactions){
                transactions = {};
            }
            const transactionsObj = generateDateObject();
            Object.keys(transactionsObj).forEach(key => {
                if (transactions.hasOwnProperty(key)) {
                    transactionsObj[key] = transactions[key];
                }
            });
            
            sortedKeys = Object.keys(transactionsObj).sort((a, b) => new Date(a) - new Date(b));
            sortedValues = sortedKeys.map(key => transactionsObj[key]);
            
            updatedChartData = {
                ...data,
                labels: sortedKeys,
                datasets: [
                    {
                        ...data.datasets[0],
                        data: sortedValues,
                    },
                ],
            };
            setActivity(stats.activity);
            setTransactionsData(updatedChartData);
            setCustomerCount(stats.applicationCount? stats.applicationCount : 0);
            setTransactionCount(stats.transactionCount? stats.transactionCount : 0);
        });
    }, [authUser]);
    
    useEffect(() => {
        const coll = collection(db, "companies", authUser.company.uid, "customers");
        const q = query(coll, where("status.status", "==", "approved"));
        getCountFromServer(q).then((snapshot) => {
            setVerifiedCustomerCount(snapshot.data().count);
        });
    }, [authUser]);
    
    useEffect(() => {
        const coll = collection(db, "companies", authUser.company.uid, "customers");
        const q = query(coll, where("status.status", "==", "rejected"));
        getCountFromServer(q).then((snapshot) => {
            setRejectedCustomerCount(snapshot.data().count);
        });
    }, [authUser]);

    return (
       <Page title="Dashboard" description="Overview">
            <Row className="my-3">
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <StatCard title="Total Applications" value={customerCount} icon={faUsers}/>
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <StatCard title="Verified Applications" value={verifiedCustomerCount} icon={faUserCheck} color="success" />
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <StatCard title="Rejected Applications" value={rejectedCustomerCount} icon={faUserXmark} color="warning" />
                </Col>
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <StatCard title="Transactions" value={transactionCount} icon={faRightLeft} color="secondary" />
                </Col>
            </Row>
            <Row className="my-3">
                <Col xs={12} md={7} className="mb-3">
                    <Card>
                        <Card.Header>
                            Applications
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div style={{"min-height": 250 + "px"}}>
                                { applicationsData && <Line data={applicationsData} options={options} /> }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={5} className="mb-3">
                    <Card>
                        <Card.Header>
                            Transactions
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div style={{"min-height": 250 + "px"}}>
                                { transactionsData && <Bar data={transactionsData} options={options} /> }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Activities activity={activity} />
                </Col>
            </Row>
       </Page>
    );
};