function generateDateObject() {
    const result = {};
    for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit'
        });
        result[formattedDate] = 0;
    }
    return result;
}

function incrementDateStat(stats) {
    const dateObject = generateDateObject();
    Object.keys(stats).forEach(key => {
        if (dateObject[key]) {
            dateObject[key] = stats[key];
        }
    });
    const today = new Date().toLocaleDateString('en-US', {month: 'short',day: '2-digit'}) 
    dateObject[today] += 1;
}

module.exports = {
    generateDateObject,
    incrementDateStat
};