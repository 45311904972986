import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import { sendVerificationSMS, verifySMSCode } from "../../firebase/otp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export default function LinkKYCVerificationForm({phoneNumber, onComplete}) {
    const [code, setCode] = useState("");
    const [user, setUser] = useState();
    const [isSMSSent, setIsSMSSent] = useState(false);
    const [verifyButton, setVerifyButton] = useState();
    const [smsVerified, setSMSVerified] = useState(false);
    const [statusMessage, setStatusMessage] = useState();

    let smsSent = false;

    useEffect(() => {
        if(!smsSent) sendSMS();
    }, []);

    const sendSMS = async () => {
        smsSent = true;
        const result = await sendVerificationSMS(phoneNumber);
        if (!result) {
            // todo implement exception
            return
        }
        if (result.success) {
            setIsSMSSent(true);
        }
    }
    
    const handleVerify = async () => {
        verifyButton.disabled = true;
        const result = await verifySMSCode(phoneNumber, code)
        if (!result) {
            // todo implement exception
            return;
        }
        if(!result.success) {
            setStatusMessage(result.message);
            verifyButton.disabled = false;
            return;
        }
        if (result.success) {
            setSMSVerified(true);
        }
    }
    
    return (
        <>
            { smsVerified ?
            <Col>
                <FontAwesomeIcon icon={faCircleCheck} className="text-primary display-6 mb-2" />
                <h1 className="card-title">SMS Verification Completed</h1>
                <p className="text-body">Your phone number is verified successfully.</p>
                <Button variant="primary" type="submit" className="d-inline-block w-25 float-end mt-3" onClick={onComplete}>Next</Button>
            </Col>
            :
            <Col>
                <h1 className="card-title">SMS Verification</h1>
                {
                !isSMSSent ?
                <Spinner variant="primary" animation="border" />
                : 
                <>
                <p className="text-body">We sent an OTP code to number: {phoneNumber}</p>
                <Form.Group className="mb-3">
                    <Form.Control 
                        type="text" 
                        className="form-control-solid"
                        placeholder="Enter your OTP code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)} 
                    />
                    { statusMessage && <Form.Text className="text-danger">{statusMessage}</Form.Text> }
                </Form.Group>
                <Button onClick={handleVerify} ref={setVerifyButton}>Verify</Button>
                </>
                }   
            </Col>
            }
        </>
    );
}
