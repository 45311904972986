import { useEffect, useState } from "react";
import { Badge, Button, Card, Dropdown, Form, InputGroup, Spinner, Table } from "react-bootstrap";
import { getDocs, limit, query, startAfter, where } from "firebase/firestore";
import { faFilter, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePlaceholder from "./TablePlaceholder";

export default function DataTable({
    tableTitle=null,
    dataQuery,
    columns,
    rowRenderer,
    pageSize,
    searchField=null,
    filters=null
}){

    const [data, setData] = useState(null);
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [lastPage, setLastPage] = useState(null);

    const [constraints, setConstraints] = useState();

    const [searchQuery, setSearchQuery] = useState(null);
    const [lastQuery, setLastQuery] = useState();
    
    const fetchData = async (afterDoc=null) => {
        let q = query(dataQuery, limit(pageSize+1));
        if(constraints && constraints.length>0){
            constraints.forEach((constraint)=>{
                q = query(q, constraint)
            })
        }
        if(afterDoc){
            q = query(q, startAfter(afterDoc))
        }
        const snapshot = await getDocs(q)
        if (snapshot.docs.length <= pageSize) {
            setLastPage(page);
        }
        setLastDoc(snapshot.docs[snapshot.docs.length === pageSize + 1 ? snapshot.docs.length - 2 : snapshot.docs.length - 1]);
        
        let updatedData = [];
        snapshot.forEach((doc) => {
            updatedData.push({
                uid: doc.id,
                ...doc.data(),
            });
        });
        updatedData = updatedData.slice(0, pageSize);
        return updatedData;
    }

    useEffect(() => {
        if(page > pageData.length && page){
            fetchData(lastDoc)
            .then((resultData) => {
                setPageData([...pageData, resultData]);
                setData(resultData);
            });
        }else{
            setData(pageData[page-1]);
        }
    }, [page]);

    useEffect(() => {
        setData(null);
        setPageData([]);
        setLastPage(null);
        setLastDoc(null);
        setPage(1);
        fetchData()
        .then((resultData) => {
            setPageData([...pageData, resultData]);
            setData(resultData);
        });
    }, [constraints])

    const handleSearchQuery = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery === lastQuery) {
            return;
        }
        if (searchQuery === ""){
            setConstraints();
            return;
        }
        if (searchQuery && searchQuery.trim().length > 0) {
            console.log(searchQuery.split(" "));
            setConstraints([where(searchField, "array-contains-any", searchQuery.split(" "))])
        }
        setLastQuery(searchQuery);
    }

    const handleNext = async () => {
        setPage(page+1);
    }

    const handlePrev = async () => {
        setPage(page-1);
    }

    return(
        <Card>
            <Card.Body className="p-0">
            { (tableTitle || searchField || filters) &&
            <Card.Header className='d-flex align-items-center'>
                {tableTitle?tableTitle:null}
                    <div className="d-flex gap-1 ms-auto">
                        { searchField &&
                        <Form onSubmit={handleSearch}>
                            <InputGroup>
                                <Form.Control type="text" size="sm" onChange={handleSearchQuery}/>
                                <Button variant="primary-soft text-primary" size="sm" type="submit">
                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                </Button>
                            </InputGroup>
                        </Form>
                        }
                        {
                            filters && filters.length>0 &&
                        <Dropdown className="d-inline-block">
                            <Dropdown.Toggle variant="secondary-soft text-secondary me-2 d-flex align-items-center" size="sm">
                                <span className="me-1 d-none d-md-block">Filters</span>
                                <span className="me-1 d-md-none d-block">
                                    <FontAwesomeIcon icon={faFilter}/>
                                </span>
                                <Badge pill bg="secondary">0</Badge>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="start">    
                            </Dropdown.Menu>
                        </Dropdown>
                        }
                    </div>
                </Card.Header>
                }
                <Table responsive hover className="small">
                    <thead>
                        <tr>
                        {
                            columns.map((column) => (
                                <th key={column}>{column}</th>
                            ))
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map(rowRenderer)}
                        {!data && <TablePlaceholder columnCount={columns.length}/>}
                    </tbody>
                </Table>
            </Card.Body>
            { (page!==1 || page!==lastPage) &&
                <Card.Footer className="d-flex">
                <Button hidden={page===1} className="me-auto" variant="primary-soft text-primary" size="sm" onClick={handlePrev} className="me-2">
                    Previous
                </Button>
                <Button hidden={!data || page===lastPage} className="ms-auto" variant="primary-soft text-primary" size="sm" onClick={handleNext}>
                    Next
                </Button>
            </Card.Footer>
            }
        </Card>
    );
}