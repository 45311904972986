import { Button, Card, Stack } from "react-bootstrap";
import StatusTag from "./StatusTag";

export default function ApplicationCard({title, name, check, status, onChangeStatus, children}){
    return(
        <Card>
            <Card.Header>
                <Stack direction="horizontal" gap={2}>
                    <div className="pb-2">
                        <span className="me-2">{title}</span>
                        <StatusTag status={status}/>
                    </div>
                    {
                        check &&
                        <Stack direction="horizontal" gap={2} className="ms-auto">
                            <Button size="sm" className="btn-success-soft text-success" name={name} value="approved" onClick={onChangeStatus}>Approve</Button>
                            <Button size="sm" className="btn-danger-soft text-danger" name={name} value="rejected" onClick={onChangeStatus}>Reject</Button>
                        </Stack>
                    }
                </Stack>
            </Card.Header>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    );
}