import { useState } from 'react';
import { Alert, Button, Form, Row } from 'react-bootstrap';
import { registerUser } from "../../firebase/register";
import { useNavigate } from 'react-router-dom';

export default function RegisterForm() {

    const [error, setError] = useState(null);
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    
    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (user.password !== user.passwordConfirm) {
            setError('Passwords do not match');
            return;
        }
        const data = {
            email: user.email,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            companyName: user.companyName
        };
        try {
            const response = await registerUser(data);
            if (response.error) {
                setError(response.error);
                setIsLoading(false);
                return;
            }
            else{
                navigate("/login");
            }
            
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    const handleChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    }

    return (
        <Form>
            { error && <Alert variant="danger">{error}</Alert> }
            <Row className="gx-3">
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">First Name</Form.Label>
                        <Form.Control className="form-control-solid" type="text" name="firstName" onChange={handleChange} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Last Name</Form.Label>
                        <Form.Control className="form-control-solid" type="text" name="lastName" onChange={handleChange} />
                    </div>
                </div>
            </Row>
            <div className="mb-3">
                <Form.Label className="small mb-1">Company Name</Form.Label>
                <Form.Control className="form-control-solid" type="text" name="companyName" onChange={handleChange} />
            </div>
            <div className="mb-3">
                <Form.Label className="small mb-1">Email</Form.Label>
                <Form.Control className="form-control-solid" type="email" name="email" onChange={handleChange} />
            </div>
            <div className="row gx-3">
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Password</Form.Label>
                        <Form.Control className="form-control-solid" type="password" name="password" onChange={handleChange} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <Form.Label className="small mb-1">Confirm Password</Form.Label>
                        <Form.Control className="form-control-solid" type="password" name="passwordConfirm" onChange={handleChange} />
                    </div>
                </div>
            </div>
            <Button type="submit" className="w-100" disabled={isLoading} onClick={handleSignUp}>Create Account</Button>
        </Form>
    );
}