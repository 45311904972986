import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../firebase/auth";
import { Link } from "react-router-dom";

export default function LevelSelectForm({ levels, setLevels, setSelectedLevel }){

    const { authUser } = useAuth();

    useEffect(() => {
        if(levels){
            return;
        }
        const q = query(collection(db, "companies", authUser.company.uid, "levels"), where("isDeleted", "==", false));
        getDocs(q).then((snapshot) => {
            console.log("fetched levels")
            if(snapshot.empty){
                setLevels([]);
                return;
            }else{
                const levels = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setLevels(levels);
            }
        });
    }, []);

    const handleLevelSelect = (e) => {
        const levelId = e.target.value;
        const level = levels.find(level => level.id === levelId);
        setSelectedLevel(level);
    }

    return(
        <Form>
        {
            !levels &&
            <Spinner variant="primary" animation="border" />
        }
        {levels && levels.length > 0 &&
        <>
            <Form.Label>KYC Level</Form.Label>
            <Form.Select className="form-control-solid" onChange={handleLevelSelect}>
                <option value="">Select a level</option>
                {
                    levels.filter(level => !level.isDeleted).map((level) => (
                        <option key={level.id} value={level.id}>{level.name}</option>
                        ))
                    }
            </Form.Select>
        </>
        }
        {
        levels && levels.length === 0 &&
        <>
            <p>You don't have any levels set.</p>                            
            <p className="p-0">
                <Link to="/dashboard/levels/create">
                    <Button variant="primary">Create Level</Button>
                </Link>
            </p>
        </>
        }
        </Form>
    );
}