export default function TablePlaceholder({columnCount, rowCount=2}) {
    return(
        <>
            {[...Array(rowCount).keys()].map((rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(columnCount).keys()].map((colIndex) => (
                            <td key={colIndex}>
                                <p className="placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                </p>
                            </td>
                        ))}
                    </tr>
                ))}
        </>
    );
}