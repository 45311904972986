import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from '../layout/Layout';
import Home from './home/Home';
import HomeRestricted from './home/HomeRestricted';
import Customers from './customers/Customers';
import CustomersCreate from './customers/CustomersCreate';
import Levels from './levels/Levels';
import LevelsEdit from './levels/LevelsEdit';
import LevelsCreate from './levels/LevelsCreate';
import CustomerProfile from './customers/CustomerProfile';
import Transactions from './transactions/Transactions';
import TransactionsCreate from './transactions/TransactionsCreate.js';
import TransactionsCreateCustomerSelect from './transactions/TransactionsCreateCustomerSelect.js';
import Settings from './Settings';
import Billing from './Billing';
import Members from './members/Members';
import MembersCreate from './members/MembersCreate.js';
import { useAuth } from '../firebase/auth.js';

export default function Dashboard() {

    const { authUser } = useAuth();

    window.addEventListener('resize', function() {
        if (window.innerWidth < 992 && document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.remove('sidenav-toggled');
        }
    });

    useEffect(() => {
        document.body.classList.add('nav-fixed');
    }, []);

    return (
        <Layout>
            <Routes>
                <Route path="" element={authUser.role==="clerk" ? <HomeRestricted/> : <Home/>}/>
                <Route path="customers" element={<Customers/>} />
                <Route path="customers/create" element={<CustomersCreate/>} />
                <Route path="customers/:customerId" element={<CustomerProfile/>} />
                <Route path="levels" element={<Levels/>} />
                <Route path="levels/create" element={<LevelsCreate/>} />
                <Route path="levels/edit/:levelId" element={<LevelsEdit/>} />
                <Route path="transactions" element={<Transactions/>} />
                <Route path="transactions/create" element={<TransactionsCreateCustomerSelect/>} />
                <Route path="transactions/create/:customerId" element={<TransactionsCreate/>} />
                <Route path="members/" element={<Members/>} />
                <Route path="members/create" element={<MembersCreate/>} />
                <Route path="billing/" element={<Billing/>} />
                <Route path="settings/*" element={<Settings/>} />
            </Routes>
        </Layout>
    );
};