import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

export default function StatCard({title, value, icon=null, color="primary"}){
    return(
        <Card className={"text-bg-"+color+" h-100"}>
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <p className="mb-1 text-sm">{title}</p>
                        <h2 className="text-white mb-0">{value!==null?value:"0"}</h2>
                    </div>
                    <div className="ms-2">
                        {icon ? <FontAwesomeIcon className="fa-2x text-white-25" icon={icon}></FontAwesomeIcon> : ""}
                    </div>
                </div>
            </div>
        </Card>
    );
}