import LayoutAuth from '../../layout/LayoutAuth';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MemberRegistrationForm from '../../forms/members/MemberRegistrationForm';

export default function MemberRegistration() {

    return (
        <LayoutAuth>
            <Col xs={12}>
                <h1 className="mb-3">Register Member</h1>
                <MemberRegistrationForm/>
            </Col>
        </LayoutAuth>
    );
}
