import { useEffect, useState } from 'react';
import { Col, Row, Spinner, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../firebase/auth';

import { db, storage } from '../../firebase/firebase';
import { doc, updateDoc, Timestamp, collection, where, query, onSnapshot } from "firebase/firestore";
import { getBlob, ref } from "firebase/storage";
import { format } from 'date-fns';
import { Gallery, Item } from 'react-photoswipe-gallery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Page from '../../layout/Page';
import DataTable from '../../components/DataTable';
import TypeBadge from '../../components/TypeBadge';
import CustomModal from '../../components/CustomModal';
import CustomerInfoTable from '../../components/CustomerInfoTable';
import RejectionModal from '../../components/RejectionModal';
import ApplicationCard from '../../components/ApplicationCard';
import CustomerHeader from '../../layout/CustomerHeader';
import CustomerNotes from '../../components/CustomerNotes';


export default function CustomerProfile() {
    
    const { authUser } = useAuth();

    const customerId = useParams().customerId;
    const [customer, setCustomer] = useState();
    
    //tabs
    const [page, setPage] = useState("verification");
    
    // status
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [status, setStatus] = useState();
    const [initialStatus, setInitialStatus] = useState();
    let totalStatus;
    
    // tags
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    
    //risk
    const [risk, setRisk] = useState(null);
    
    // transactions
    const [transactionsLoaded, setTransactionsLoaded] = useState(false);
    
    // documents
    const [documentBlobs, setDocumentBlobs] = useState({});
    
    const navigate = useNavigate();

    // fetch customer document
    useEffect(() => {
        const docRef = doc(db, "companies", authUser.company.uid, "customers", customerId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (!doc.exists()) {
                navigate("..", { relative: "path" });
                return;
            }
            const customerData = doc.data();
            setStatus(customerData.status);
            if(customerData.status.risk) setRisk(customerData.status.risk);
            if(customerData.status.tags) setSelectedTags(customerData.status.tags);
            setInitialStatus(customerData.status);
            setCustomer({
                id: doc.id,
                ...customerData,
            });
        }, (error) => {
            console.log("Error listening to document:", error);
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => unsubscribe();
    }, [customerId, navigate, authUser.company.uid]);


    // after documents are loaded, fetch blobs
    useEffect(() => {
        if (!customer) {
            return;
        }
        customer.documents.filter((document) => document.url !== "").forEach((document) => {
            if (documentBlobs[document.name]) {
                return;
            }
            const storageRef = ref(storage, document.url);
            getBlob(storageRef)
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                // Create an image object to load the image from the blob URL
                const img = new Image();
                img.onload = () => {
                    // Once the image is loaded, extract the width and height
                    const dimensions = { width: img.naturalWidth, height: img.naturalHeight };
                    // Update the state to include both the blob URL and the dimensions
                    setDocumentBlobs((prevBlobs) => {
                        return {
                            ...prevBlobs,
                            [document.name]: { url, ...dimensions }
                        }
                    });
                };
                img.src = url; // This triggers the image loading
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            });
        });
    }, [customer]);    

    const handleStartCheck = () => {
        setCheck(true);
        setPage("verification");
    }

    const onChangeStatus = (e) => {
        const newStatus = e.target.value;
        const name = e.target.name;
        if (name === "fields"){
            setStatus({
                ...status,
                fields: newStatus
            });
        }
        else{
            setStatus({
                ...status,
                documents: {
                    ...status.documents,
                    [name]: newStatus
                }
            });
        }
    }

    const getNewStatusObject = (status) => {
        const obj = status;
        const pendingCount = Object.values(status.documents).filter((status) => status === 'pending').length + (status.fields === 'pending' ? 1 : 0);
        const approvedCount = Object.values(status.documents).filter((status) => status === 'approved').length + (status.fields === 'approved' ? 1 : 0);
        const rejectedCount = Object.values(status.documents).filter((status) => status === 'rejected').length + (status.fields === 'rejected' ? 1 : 0);
        
        if (pendingCount === 0 && rejectedCount === 0){
            obj.status = 'approved';
        }
        else if (rejectedCount > 0){
            obj.status = 'rejected';
        }
        else if (approvedCount === 0 && rejectedCount === 0){
            obj.status = 'pending';
        }else{
            obj.status = 'in progress'
        }

        console.log(obj);
        return obj;
    }

    const submitStatus = () => {
        setLoading(true);
        const docRef = doc(db, "companies", authUser.company.uid, "customers", customerId);
        const updatedStatus = getNewStatusObject(status);
        if (updatedStatus.status === 'rejected'){
            updatedStatus.tags = selectedTags;
        }else{
            updatedStatus.tags = [];
        }

        updateDoc(docRef, {
            status: updatedStatus,
            updatedAt: Timestamp.now()
        })
        .then(() => {
            setStatus(updatedStatus);
            setCustomer({
                ...customer,
                status: updatedStatus
            });
            setLoading(false);
        })
        .catch((error) => {
            alert("Error updating document: ", error);
            setStatus(initialStatus);
            setLoading(false);
        });
    }

    useEffect(()=>{
        if(page==="transactions" && !transactionsLoaded){
            setTransactionsLoaded(true);
        }
    }, [page])

    const onSave = () => {
        setCheck(false);
        const newStatusObj = getNewStatusObject(status);
        if (newStatusObj.status === 'rejected'){
            setTagModalOpen(true);
        }else{
            submitStatus();
        }
    }
    
    const handleRejectWithTags = () => {
        setTagModalOpen(false);
        submitStatus();
    };
    
    const handleCancelCheck = () => {
        setCheck(false);
        setStatus(initialStatus);
    }

    const onSelectRisk = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const newRisk = e.target.name;
        const docRef = doc(db, "companies", authUser.company.uid, "customers", customerId);
        updateDoc(docRef, {
            "status.risk": newRisk
        })
        .then(() => {
            setRisk(newRisk);
        });
    }


    const transactionDataQuery = query(collection(db, 'companies', authUser.company.uid, 'transactions'), where('customer', '==', customerId));
    const columns = ["Date", "Type", "Amount", "Currency", "Created At", "Created By"];
    const pageSize = 5;
    const rowRenderer = (transaction) => {
        return (
            <tr key={transaction.uid} style={{ cursor: 'pointer' }}>
                <td>{format(transaction.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td><TypeBadge>{transaction.type}</TypeBadge></td>
                <td>{transaction.amount}</td>
                <td>{transaction.currency}</td>
                <td>{format(transaction.createdAt.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td>{transaction.createdByName}</td>
            </tr>
        );
    };

    return (
        <Page.AdminOrOfficer title="Customer Profile" compact>
            { customer &&
            <Tab.Container activeKey={page} onSelect={(p)=>setPage(p)}>
            <CustomerHeader customer={customer} risk={risk} onSelectRisk={onSelectRisk} check={check} onCheck={handleStartCheck} onCancel={handleCancelCheck} onSave={onSave}/>
            <Row className="mb-3">
                <Tab.Content>
                    <Tab.Pane eventKey="verification">
                        <Col className="mb-2">
                            <ApplicationCard title="Personal Information" name="fields" check={check} status={status.fields} onChangeStatus={onChangeStatus}>
                                <CustomerInfoTable customer={customer} />
                            </ApplicationCard>
                        </Col>
                        {customer.documents.map((document) => (
                        <Col md={document.length>1?6:12} key={document.name} className="mb-3">
                            <ApplicationCard title={document.name} name={document.name} check={check} status={status.documents[document.name]} onChangeStatus={onChangeStatus}>
                                {
                                    document.url ?
                                    documentBlobs[document.name] ?
                                        <Gallery>
                                            <Item
                                                key={document.name}
                                                original={documentBlobs[document.name].url}
                                                thumbnail={documentBlobs[document.name].url}
                                                width={documentBlobs[document.name].width}
                                                height={documentBlobs[document.name].height}
                                            >
                                                {({ ref, open }) => (
                                                    <img ref={ref} onClick={open} src={documentBlobs[document.name].url} className="img-fluid w-25" style={{ maxWidth: "200px" }} alt={document.name} />
                                                )}
                                            </Item>
                                        </Gallery>
                                        :
                                        <Spinner variant="primary" animation="border"/>
                                        :
                                        <div className="bg-gray-100 rounded d-inline-block text-center p-4">
                                            <FontAwesomeIcon icon={faQuestionCircle} className="display-6 text-gray-500"/>
                                            <p className="small mt-2 text-gray-500">Document not uploaded</p>
                                        </div>
                                }
                            </ApplicationCard>
                            
                        </Col>
                        ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="transactions">
                        <Col>
                            {
                            transactionsLoaded &&
                            <DataTable dataQuery={transactionDataQuery} columns={columns} rowRenderer={rowRenderer} pageSize={pageSize}/>
                            }
                        </Col>
                    </Tab.Pane>
                    <Tab.Pane eventKey="notes">
                        <Col>
                            <CustomerNotes customer={customer} />
                        </Col>
                    </Tab.Pane>
                </Tab.Content>
            </Row>
            <CustomModal.Loading show={loading} title="Updating status"/>
            <RejectionModal show={tagModalOpen} setShow={setTagModalOpen} setSelectedTags={setSelectedTags} selectedTags={selectedTags} onSubmit={handleRejectWithTags}/>
            </Tab.Container>
        }
        </Page.AdminOrOfficer>
    );
};