import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import countries from '../utils/countries';

export default function PhoneInput({ name="phone", onChange }) {
    const [areaCode, setAreaCode] = useState("+1");
    const [number, setNumber] = useState("");
    
    useEffect(() => {
        let event = { target: { name: "phone", value: areaCode+number } };
        onChange(event);
    }, [areaCode, number]); // Depend on areaCode, number, and onChange to re-run

    const handleAreaCodeChange = (event) => {
        setAreaCode(event.target.value);
    };

    const handleNumberChange = (event) => {
        setNumber(event.target.value);
    };

    return (
        <>
            <Form.Label>Phone</Form.Label>
            <Row>
                <Col xs={5}>
                    <Form.Select required className="form-control-solid" value={areaCode} onChange={handleAreaCodeChange}>
                        {countries.map((country) => (
                            <option key={country.countryNameEn} value={country.countryCallingCode}>{country.flag} {country.countryCallingCode} {country.countryNameEn}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col xs={7}>
                    <Form.Control required className="form-control-solid" type="tel" value={number} onChange={handleNumberChange} placeholder="000-00-00" />
                </Col>
            </Row>
            <Form.Control required className="form-control-solid" hidden type="text" name={name} value={areaCode + number} />
        </>
    );
}
