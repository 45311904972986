import { Row, Card, Col, ListGroup, Stack, Button, Spinner } from 'react-bootstrap';
import Page from '../../layout/Page';
import { useNavigate } from 'react-router-dom';

import { db } from '../../firebase/firebase';
import { useAuth } from '../../firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { removeMember } from '../../firebase/members';

import CustomModal from '../../components/CustomModal';


export default function Members() {

    const { authUser } = useAuth();

    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [info, setInfo] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const q = query(collection(db, "companies", authUser.company.uid, "members"), where("isDeleted", "!=", true));
        getDocs(q).then((snapshot) => {
        const members = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setMembers(members);
            setIsLoading(false);
        });
    }, [authUser.company.uid]);

    const handleDeleteClick = (member) => {
        setMemberToDelete(member);
        setShowConfirmation(true);
    }

    const handleRemoveMember = async () => {
        const email = memberToDelete.email;
        setShowConfirmation(false);
        setLoadingModal(true);
        try{
            const result = await removeMember(email);
            if(result.success){
                setInfo(result.message);
                setLoadingModal(false);
            }else{
                setError(result.message)
                setLoadingModal(false);
            }
            return result;
        }
        catch(error){
            setError(error.message)
            setLoadingModal(false);
        }
    }

    return (
        <Page.Admin title="Team" description="Settings">
            { isLoading ? <Spinner animation="border" role="status" /> 
            :
            <Row>
                <Col className='mb-3'>
                    <Card>
                        <Card.Header className='d-flex justify-content-between align-items-center'>
                            Members
                            <Button onClick={()=>{
                                navigate("./create", 
                                {relative: "path"})
                            }}
                            size="sm"
                            variant="primary-soft text-primary">
                                Create New
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                {members.map((member) => (
                                    <ListGroup.Item className="py-3">
                                        <Stack direction="horizontal" gap={2}>
                                            <Stack direction="vertical">
                                                <p className="text-dark line-height-normal mb-1">{member.firstName} {member.lastName}</p>
                                                <p className="small text-muted line-height-normal mb-1">{member.email}</p>
                                                <p className="small text-primary line-height-normal mb-0">{member.role}</p>
                                            </Stack>          
                                            {member.role !== "admin" &&
                                                <Stack direction="horizontal" gap={2} className="ms-auto">
                                                    <Button size="sm" className="btn-danger-soft text-danger" onClick={()=>handleDeleteClick(member)}>Delete</Button>
                                                </Stack>
                                            }                                  
                                        </Stack>
                                    </ListGroup.Item>
                                ))}                                                           
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        }
        <CustomModal.Loading show={loadingModal} />
        <CustomModal.Info show={info} close={()=>setInfo(null)} message={info} />
        <CustomModal.Error show={error} close={()=>setError(null)} message={error} />
        <CustomModal.Delete show={showConfirmation} close={()=>setShowConfirmation(false)} message="Are you sure you want to delete this member?" handleDelete={async () => await handleRemoveMember()} />
        </Page.Admin>
    );
};