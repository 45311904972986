export const sendVerificationSMS = async (phone) => {
    const body = {
        "phone": phone.toString()
    }
    return fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/sendVerificationSMS', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => {
        return response.json()
    })
}

export const verifySMSCode = async  (phone, code) => {
    const body = {
        "phone": phone.toString(),
        "code": code.toString()
    }
    return fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/verifySMSCode', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}

export const sendVerificationEmail = async (email) => {
    const body = {
        "email": email.toString()
    }
    return fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/sendVerificationEmail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => {
        return response.json()
    })
}

export const verifyEmailCode = async  (email, code) => {
    const body = {
        "email": email.toString(),
        "code": code.toString()
    }
    return fetch(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL+'/verifyEmailCode', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}