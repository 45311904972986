import { Card, Col, Row } from 'react-bootstrap';
import Page from '../../layout/Page';
import TransactionCreateForm from "../../forms/transactions/TransactionCreateForm"
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AuthControl from '../../components/AuthControl';

export default function TransactionsCreate() {

    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    
    useEffect(() => {
        if(location.state && location.state.customer){
            setSelectedCustomer(location.state.customer);
        }else{
            navigate("..", { relative: "path" })
        }
    }, [location]);

    return (
        <Page title="Create Transaction" description="Transactions" compact={true}>
            {selectedCustomer && 
            <Row>
                <Col xs={12} md={8}>
                    <TransactionCreateForm customer={selectedCustomer} />
                </Col>
                <Col xs={12} md={4}>
                    <Card className="mb-3">
                        <Card.Header>
                            <span className="me-2">Customer</span>
                        </Card.Header>
                        <Card.Body>
                        <Row>
                            <Col xs={12} sm={6} md={12}>
                                <p className="text-muted mb-0">First Name</p>
                                <p className="text-body mb-2">{selectedCustomer.firstName}</p>
                                <p className="text-muted mb-0">Last Name</p>
                                <p className="text-body mb-2">{selectedCustomer.lastName}</p>
                            </Col>
                            <AuthControl.AdminOrOfficer>
                                <Col xs={12} sm={6} md={12}>
                                    <p className="text-muted mb-0">Date of Birth</p>
                                    <p className="text-body mb-2">{selectedCustomer.dateofbirth}</p>
                                    <p className="text-muted mb-0">Phone</p>
                                    <p className="text-body mb-2">{selectedCustomer.phone}</p>
                                </Col>
                                <Col xs={12} sm={6} md={12}>
                                    <p className="text-muted mb-0">Email</p>
                                    <p className="text-body mb-2">{selectedCustomer.email}</p>
                                    <p className="text-muted mb-0">Nationality</p>
                                    <p className="text-body mb-2">{selectedCustomer.nationality}</p>
                                </Col>
                                <Col xs={12}>
                                {
                                    selectedCustomer.fields && selectedCustomer.fields.map((field) => (
                                        <Row key={field.name}>
                                            <p className="text-muted mb-0">{field.name}</p>
                                            <p className="text-body mb-2">{field.value}</p>
                                        </Row>
                                    )) 
                                }
                                </Col>
                            </AuthControl.AdminOrOfficer>
                        </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            }
        </Page>
    );
};
