import { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../firebase/auth';
import CustomModal from '../../components/CustomModal';

import { db } from '../../firebase/firebase';
import { Timestamp, addDoc, collection } from 'firebase/firestore';

export default function TransactionCreateForm({customer}) {

    const { authUser } = useAuth();

    const types = [
        "Deposit",
        "Withdrawal",
        "Payment",
        "Crypto Sales",
        "Crypto - Fiat Conversion",
        "Crypto Payment",
        "Currency Exchange",
        "Gold Exchange",
        "Trading",
        "Insurance",
        "Real Estate",
        "Gambling",
        "Airbnb",
        "Car Rental",
        "Other"
    ]

    const currencies = [
        "USD",
        "EUR",
        "GBP",
        "AUD",
        "CAD",
        "CHF",
        "JPY",
        "TRY",
        "BTC",
        "ETH",
        "USDT",
        "USDC",
        "Other"
    ]

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [transaction, setTransaction] = useState({customer: customer.uid, customerDetails: customer});

    const handleTypeSelect = (event) => {
        setTransaction({
            ...transaction,
            type: event.target.value,
        });
    }
    
    const handleCurrencySelect = (event) => {
        setTransaction({
            ...transaction,
            currency: event.target.value,
        });
    }

    const handleAmount = (event) => {
        setTransaction({
            ...transaction,
            amount: parseFloat(event.target.value),
        });
    }

    const handleDate = (event) => {
        setTransaction({
            ...transaction,
            date: Timestamp.fromDate(new Date(event.target.value)),
        });
    }

    const setNow = () => {
        setTransaction({
            ...transaction,
            date: Timestamp.now(),
        });
        document.querySelector("input[type='datetime-local']").value = new Date().toISOString().slice(0, -8);
    }

    const handleAccount = (event) => {
        setTransaction({
            ...transaction,
            account: event.target.value,
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const transactionObj = {
            ...transaction,
            createdBy: authUser.uid,
            createdByName: authUser.displayName,
            createdAt: Timestamp.now(),
        }
        
        const newTransactionRef = collection(db, "companies", authUser.company.uid, "transactions");
        addDoc(newTransactionRef, transactionObj)
        .then(() => {
            setIsLoading(false);
            navigate("../..", { relative: "path" })
        })
        .catch((error) => {
            setIsLoading(false);
            alert("Error adding document: ", error);
        });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Card className="mb-3">
                <Card.Header>
                    Transaction Details
                </Card.Header>
                <Card.Body>
                    <Row className="gx-3">
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>Date</Form.Label>
                                <InputGroup>
                                    <Form.Control required type="datetime-local" className="form-control-solid" onChange={handleDate}/>
                                    <Button variant="primary-soft text-primary" onClick={setNow}>Now</Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>Type</Form.Label>
                                <Form.Select required className="form-control-solid" onChange={handleTypeSelect}>
                                    <option value="">Select a type</option>
                                    {
                                        types.map((type) => (
                                            <option value={type}>{type}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control required type="number" className="form-control-solid" onChange={handleAmount}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label>Currency</Form.Label>
                                <Form.Select required className="form-control-solid" onChange={handleCurrencySelect}>
                                    <option value="">Select a currency</option>
                                    {
                                        currencies.map((currency) => (
                                            <option value={currency}>{currency}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="gx-3">
                        <Form.Group className='mb-3'>
                            <Form.Label>Account</Form.Label>
                            <Form.Control required type="text" className="form-control-solid" onChange={handleAccount}/>
                            <Form.Text className="text-xs text-muted m-0">Bank Account, Crypto wallet address etc.</Form.Text>
                        </Form.Group>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <Button type="submit" variant="primary" className="float-end">Submit</Button>
                </Card.Footer>
            </Card>
            <CustomModal.Loading show={isLoading} title="Creating Transaction"/>
        </Form>
    );
}