import { functions } from './firebase'
import { httpsCallable } from 'firebase/functions'

const createMemberInvitation = httpsCallable(functions, 'createMemberInvitation');
const removeMemberFunction = httpsCallable(functions, 'removeMember');

export const inviteMember = async (data) => {
    try {
        const response = await createMemberInvitation(data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const removeMember = async (email) => {
    const data = { email: email };
    try {
        const response = await removeMemberFunction(data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}
