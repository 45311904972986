import { Accordion, Button, Modal, ToggleButton } from "react-bootstrap";

export default function RejectionModal({show, setShow, selectedTags, setSelectedTags, onSubmit}){
    
    const rejectionTags = {
        "Regulations violations":[
            "Age",
            "Duplicate",
            "Wrong region"
        ],
        "Suspected fraud":[
            "Fake",
            "Blocklist",
            "Selfie mismatch",
            "High risk profile",
            "Forced verification",
            "Printed copy of ID",
            "Deepfake",
            "Liveness with phone",
            "Template"
        ],
        "Selfie issues":[
            "Different people",
            "Bypass attempts"
        ],
        "Compromised persons":[
            "PEP",
            "Sanction lists",
            "Criminal records",
            "Adverse media",
            "Incomplete data"
        ],
        "Different docs":[
            "Different docs"
        ],
        "Spam":[
            "Spam"
        ],
        "KYC":[
            "Not enough data in source",
            "KYC skipped",
            "Data mismatch"
        ]
    }

    const handleHide = () => setShow(false);

    const handleTagToggle = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags((prevTags) => prevTags.filter((prevTag) => prevTag !== tag));
        } else {
            setSelectedTags((prevTags) => [...prevTags, tag]);
        }
    };

    return(
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>Select Rejection Tags</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <form>
                    <div>
                        <Accordion flush alwaysOpen>
                        {Object.entries(rejectionTags).map(([category, tags]) => (
                            <Accordion.Item eventKey={category}>
                                <Accordion.Header>
                                    {category}
                                    <span className="ms-2 badge bg-danger-soft text-danger">
                                        {
                                            selectedTags.filter(tag => tags.includes(tag)).length > 0 && selectedTags.filter(tag => tags.includes(tag)).length
                                        }
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {tags.map((tag) => (
                                        <span key={tag} className="mb-3">
                                            <ToggleButton
                                                id={`tag-${tag.toLowerCase()}`}
                                                type="checkbox"
                                                value={tag}
                                                variant="outline-danger me-1 mb-1"
                                                size="sm"
                                                checked={selectedTags && selectedTags.includes(tag)}
                                                onChange={() => handleTagToggle(tag)}
                                            >
                                                {tag}
                                            </ToggleButton>
                                        </span>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                        </Accordion>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary-soft text-primary" onClick={handleHide}>
                    Cancel
                </Button>
                <Button variant="danger-soft text-danger" onClick={onSubmit}>
                    Reject Application
                </Button>
            </Modal.Footer>
        </Modal>
    );
}