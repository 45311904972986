export default function FooterAuth() {
    return (
        <div className="row mt-auto mb-2 w-100">
            <hr/>
            <div className="col-md-6 text-cennter text-md-start small p-0">Copyright &copy; TrueKYC {new Date().getFullYear()}</div>
            <div className="col-md-6 text-cennter text-md-end small p-0">
                <a href="#!">Privacy Policy</a>
                &middot;
                <a href="#!">Terms &amp; Conditions</a>
            </div>
        </div>
    )
}
