import { Badge } from "react-bootstrap";

export default function TypeBadge({ children }){
        
    const transactionTypes = [
        "Deposit",
        "Withdrawal",
        "Payment",
        "Crypto Sales",
        "Crypto - Fiat Conversion",
        "Crypto Payment",
        "Currency Exchange",
        "Gold Exchange",
        "Trading",
        "Insurance",
        "Real Estate",
        "Gambling",
        "Airbnb",
        "Car Rental",
        "Other"
    ]
    
    const variants = ["primary", "secondary", "success", "danger", "warning", "info"]
    
    const index = transactionTypes.indexOf(children);
    const variant = variants[index % variants.length];

    return (
        <Badge bg={variant+"-soft"} text={variant}>{children}</Badge>
    );
}