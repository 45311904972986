import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterAuth from "./FooterAuth";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons";

const LayoutAuth = ({children}) =>{
    return (
        <div>
            <div id="layoutAuthentication">
                    <div className="row flex-grow-1">
                        <div className="col-12 col-lg-6 col-xl-5 bg-white">
                            <div className="d-flex flex-column h-100 align-items-center px-5">
                                <div className="d-flex align-items-center w-100 justify-content-center justify-content-lg-start mt-3 mb-5">
                                    <FontAwesomeIcon icon={faFingerprint} className="bg-gradient-primary-to-secondary text-white fs-1 p-2 rounded" />
                                    <span className="text-dark ms-2 fs-4">True<span className="fw-bold">KYC</span></span>
                                </div>
                                <div className="d-flex flex-column w-100 flex-grow-1 justify-content-start justify-content-lg-center align-items-center mb-5">
                                    {children}
                                </div>
                                <FooterAuth />
                            </div>
                        </div>
                        <div className="d-none d-lg-flex flex-column col-6 col-xl-7 bg-gradient-primary-to-secondary justify-content-center px-5 py-5">
                            <div className="d-flex flex-column px-5 py-5">
                                <div className="flex-grow-1 px-3">
                                    <h1 className="fs-6 text-white-50">KYC Verification Made Simple</h1>
                                    <h1 className="display-6 text-white">Empower Your Compliance Journey</h1>
                                    <p className="lead text-white-50 fs-3">Streamline your KYC compliance with seamless customer onboarding, real-time verification, and robust security features designed for businesses of all sizes.</p>
                                </div>
                                <img src="/img/banner-1.png" className="img-fluid py-5" alt="Auth Screens" />
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default LayoutAuth;