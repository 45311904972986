import { faBars, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useAuth } from "../firebase/auth";
import { Link } from "react-router-dom";

export default function NavbarMobile() {

    const { logout, authUser } = useAuth();
    const name = authUser.displayName;
    const email = authUser.email;

    const role = authUser.role === 'admin' ? 'Admin' : authUser.role === 'officer' ? 'Officer' : 'Clerk';


    const toggleSidenav = () => {
        document.body.classList.toggle('sidenav-toggled');
        document.getElementById("sidenavOverlay").classList.toggle('d-none');
    };

    useEffect(() => {
        const overlay = document.getElementById("sidenavOverlay");
        if (overlay) {
            overlay.addEventListener('click', toggleSidenav);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (overlay) {
                overlay.removeEventListener('click', toggleSidenav);
            }
        };
    }, []);


    return(
        <div className="sidenav-dark d-flex justify-content-between d-lg-none">
            <button className="btn btn-icon btn-transparent-light me-2 ms-lg-2 me-lg-0 d-lg-none" id="sidebarToggle" onClick={toggleSidenav}>
                <FontAwesomeIcon icon={faBars}/>
            </button>
            <div className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
                <button className="btn btn-icon btn-transparent-light dropdown-toggle" id="navbarDropdownUserImage" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FontAwesomeIcon icon={faUser}/>
                </button>
                <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                    <h6 className="dropdown-header d-flex align-items-center">
                        <div className="dropdown-user-details">
                            <div className="dropdown-user-details-email text-primary text-xs">{role}</div>
                            <div className="dropdown-user-details-name text-sm fw-bold">{name}</div>
                            <div className="dropdown-user-details-email text-xs">{email}</div>
                        </div>
                    </h6>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" onClick={logout}>
                        <div className="dropdown-item-icon">
                            <FontAwesomeIcon icon={faRightFromBracket}/>
                        </div>
                        Logout
                    </Link>
                </div>
            </div>
        </div>
    );
}