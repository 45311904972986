import { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useAuth } from '../../firebase/auth';
import { createCustomerForCompany } from '../../firebase/firestore';
import { uploadFile } from '../../firebase/storage';
import { updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../components/CustomModal';
import PhoneInput from '../../components/PhoneInput';
import CountryInput from '../../components/CountryInput';
import LinkKYCVerificationForm from '../link/LinkKYCVerificationForm';
import LinkKYCEmailVerificationForm from '../link/LinkKYCEmailVerificationForm';

let isSMSVerified = false;
let isEmailVerified = false;

export default function CustomerCreateForm({level}) {

    const { authUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState({});
    const [files, setFiles] = useState({});
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    const navigate = useNavigate();

    const handleChange = (event) => {
        setCustomer({
            ...customer,
            [event.target.name]: event.target.value,
        });
    };

    const handleFieldsChange = (event) => {
        setCustomer({
            ...customer,
            fields: {
                ...customer.fields,
                [event.target.name]: event.target.value,
            }
        });
    };

    const handleFile = (event) => {
        setFiles({
            ...files,
            [event.target.name]: event.target.files[0], // Store uploaded file
        });
    }

    const handleSMSVerified = () => {
        setShowSMSModal(false);
        isSMSVerified = true;
        handleSubmit(new Event("submit"));
    }
    
    const handleEmailVerified = () => {
        isEmailVerified = true;
        setShowEmailModal(false);
        handleSubmit(new Event("submit"));
    }

    const handleSubmit = (event) => {
        if(event) event.preventDefault();
        if(level.verifications && level.verifications.length > 0 && level.verifications.includes("SMS Verification") && !isSMSVerified){
            setShowSMSModal(true);
            return;
        }
        else if(level.verifications && level.verifications.length > 0 && level.verifications.includes("E-mail Verification") && !isEmailVerified){
            setShowEmailModal(true);
            return;
        }
        else{
            handleCreate();
        }
    }

    const handleCreate = async () => {
        setIsLoading(true);
        if(customer.fields){
            customer.fields = Object.entries(customer.fields).map(([name, value]) => ({ name, value }));
        }else{
            customer.fields = [];
        }
        customer.createdBy = authUser.uid;
        customer.createdByName = authUser.displayName;

        // Create the customer in Firestore
        const createdCustomer = await createCustomerForCompany(authUser.company.uid, customer, level);
        let uploadedFiles = []

        // Upload files to Firebase Storage
        for (const [document, file] of Object.entries(files)) {
            const fileBucket = `companies/${authUser.company.uid}/customers/${createdCustomer.id}`;
            const fileBucketPath = await uploadFile(file, fileBucket, document);
            if(!fileBucketPath){
                setIsLoading(false);
                return;
            }
            uploadedFiles.push({name:document, url:fileBucketPath});
        }

        const newCustomer = {
            documents: uploadedFiles,
            status : {
                status: 'pending',
                fields: 'pending',
                documents: uploadedFiles.reduce((acc, file) => ({ ...acc, [file.name]: "pending" }), {}),
            }
        }
        
        if(level.verifications && level.verifications.length > 0){
            newCustomer.status.verifications = level.verifications.reduce((acc, verification) => ({ ...acc, [verification]: verification==="SMS Verification" ? isSMSVerified : isEmailVerified }), {});
        }

        // Update the customer in Firestore with the file URLs
        await updateDoc(createdCustomer, newCustomer);
        setIsLoading(false);
        navigate("..", { relative: "path" })
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Card className="mb-3">
                <Card.Header>
                    Personal Information
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-2'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required className="form-control-solid" type="text" name="firstName" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group className='mb-2'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required className="form-control-solid" type="text" name="lastName" onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className='mb-2'>
                        <CountryInput onChange={handleChange}/>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control required className="form-control-solid" type="date" name="dateofbirth" onChange={handleChange} />
                    </Form.Group>
                    <Row>
                        <Form.Group className='mb-2'>
                            <PhoneInput onChange={handleChange}/>
                        </Form.Group>
                        <Form.Group className='mb-2'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control required className="form-control-solid" type="text" name="email" onChange={handleChange} />
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
            { level.fields && level.fields.length > 0 &&
            <Card className="mb-3">
                <Card.Header>
                    Additional Information
                </Card.Header>
                <Card.Body>
                    {
                        level.fields.map((field) => (
                            <Form.Group key={field.name} className='mb-2'>
                                <Form.Label>{field.name}</Form.Label>
                                <Form.Control required className="form-control-solid" type="text" name={field.name} onChange={handleFieldsChange} />
                            </Form.Group>
                        ))
                    }
                </Card.Body>
            </Card>
            }
            <Card className="mb-3">
                <Card.Header>
                    Required Documents
                </Card.Header>
                <Card.Body>
                    {
                        level.documents.map((document) => (
                            <Form.Group key={document} className='mb-2'>
                                <Form.Label>{document}</Form.Label>
                                <Form.Control required className="form-control-solid" type="file" name={document} onChange={handleFile} />
                            </Form.Group>
                        ))
                    }
                </Card.Body>
            </Card>
            <Button variant="primary" type="submit">Submit</Button>
            <CustomModal.Loading show={isLoading} title="Creating Customer"/>
            <CustomModal show={showSMSModal}>
                <LinkKYCVerificationForm phoneNumber={customer.phone} onComplete={handleSMSVerified} />
            </CustomModal>
            <CustomModal show={showEmailModal}>
                <LinkKYCEmailVerificationForm email={customer.email} onComplete={handleEmailVerified} />
            </CustomModal>
        </Form>
    );
}